import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from './sagas/rootSaga';
import { userReducer } from './userReducer';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    userReducer,
});

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware),
);

export const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);
