import React from 'react';

export const ArrowIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
            d="M14.32 10.47a.65.65 0 00.17-.55.61.61 0 00-.17-.39L6.55 1.19A.59.59 0 006.12 1a.61.61 0 00-.44.19.7.7 0 000 .94L13 10l-7.32 7.87a.7.7 0 000 .94.59.59 0 00.44.19.57.57 0 00.43-.19z"
            fill="#2d2e2c"
            stroke="#2d2e2c"
            strokeWidth=".8"
        />
    </svg>
);
