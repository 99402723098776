import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setState } from 'core/redux/actions';
import {
    getConvertedStoreHours,
    days,
} from '../../utils/getConvertedStoreHours';

import { AppointmentButton } from 'core/components/AppointmentButton';
import { ShareButton } from 'core/components/ShareButton';

import {
    getSyledStoreHeader,
    getStyledStoreImageContainer,
    getStyledStoreImage,
    getStyledStoreAddressContainer,
    getStyledStoreName,
    getStyledStoreAddress,
    getStyledHoursHeader,
    getStyledHoursContainer,
    getStyledHoursRow,
    getStyledDaysField,
    getStyledDateField,
    getStyledHoursField,
    getStyledStoreInformationContainer,
    getStyledButtonContainer,
    getStyledCopyToast,
    getStyledHoursClosed
} from './style';

const StyledStoreInformationContainer = styled.div(
    getStyledStoreInformationContainer
);
const StyledStoreHeader = styled.h2(getSyledStoreHeader);
const StyledStoreImageContainer = styled.div(getStyledStoreImageContainer);
const StyledStoreImage = styled.img(getStyledStoreImage);
const StyledStoreAddressContainer = styled.div(getStyledStoreAddressContainer);
const StyledStoreName = styled.h2(getStyledStoreName);
const StyledStoreAddress = styled.p(getStyledStoreAddress);
const StyledHoursHeader = styled.h2(getStyledHoursHeader);
const StyledHoursContainer = styled.ul(getStyledHoursContainer);
const StyledHoursRow = styled.li(getStyledHoursRow);
const StyledDaysField = styled.span(getStyledDaysField);
const StyledDateField = styled.span(getStyledDateField);
const StyledHoursField = styled.span(getStyledHoursField);
const StyledButtonContainer = styled.div(getStyledButtonContainer);
const StyledClosedHours = styled.span(getStyledHoursClosed)
const StyledCopyToast = styled.p(getStyledCopyToast);

export const Store = ({ openModal }) => {
    const dispatch = useDispatch();
    const hoursRef = useRef();

    const { t } = useTranslation();

    const isBotToastDisplayed = useSelector(
        (state) => state.userReducer.isBotToastDisplayed
    );
    const userState = useSelector((state) => state?.userReducer?.user);
    const { firstName, imageUrl, sfscEmployeeId } = userState;
    const storeState = useSelector((state) => state?.userReducer?.store);
    const {
        name,
        cityC,
        countryC,
        address1C,
        zipC,
        country,
        headerImageEmailDesktopC,
    } = storeState || {};
    const storeHoursAPI = useSelector(
        (state) => state?.userReducer?.store?.storeHours
    );
    const setScroll = useSelector((state) => state?.userReducer?.setScroll);
    
    const ifKansas = name==='Kansas City'
    //let storeHoursArray = getConvertedStoreHours(storeHoursAPI);

    useEffect(() => {
        if (hoursRef.current && setScroll) {
            setTimeout(() => {
                hoursRef.current.scrollIntoView({ behavior: 'smooth' });
                dispatch(setState({ setScroll: false }));
            }, 500);
        }
    }, [setScroll]);

    return (
        <>
            <StyledStoreInformationContainer>
                {isBotToastDisplayed && (
                    <StyledCopyToast
                        className="botToast"
                    >
                        {t('copiedToClipboard')}
                    </StyledCopyToast>
                )}
                <ShareButton
                    label=""
                    labelText={`${t('shareText')}`}
                    isDefault={false}
                    imageUrl={imageUrl}
                    employeeName={firstName}
                    sfscEmployeeId={sfscEmployeeId}
                />
                
                {storeState && (
                    <>
                        <StyledStoreHeader>{t('theStore')}</StyledStoreHeader>
                        <StyledStoreImageContainer>
                            <StyledStoreImage
                                alt="store-img"
                                src={headerImageEmailDesktopC}
                                data-testid='store-image'
                            />
                        </StyledStoreImageContainer>
                        <StyledStoreAddressContainer>
                        
                        
                        <StyledStoreName data-testid='store-name'>{name}</StyledStoreName>
                            
                        {!ifKansas && (  
                        <>  
                            <StyledStoreAddress data-testid='store-address'>{address1C}</StyledStoreAddress>
                            
                                <StyledStoreAddress data-testid='store-address-info'>
                                {cityC}
                                {', '}
                                {zipC} <br />
                                {countryC}
                            </StyledStoreAddress>
                            <StyledStoreAddress data-testid='store-country'>{country}</StyledStoreAddress>
                                </>
                            )}
                        </StyledStoreAddressContainer>

                        {/* {storeHoursArray && storeHoursArray.length > 0 && (
                            <StyledHoursHeader ref={hoursRef}>
                                {t('openingHours')}
                            </StyledHoursHeader>
                        )} */}
                        
                            <StyledHoursContainer ref={hoursRef} data-testid='store-hours'>
                            {days.map((day) => (
                                <StyledHoursRow>
                                    <StyledDaysField>{day}</StyledDaysField>{' '}
                                    
                                      {ifKansas?
                                      (<StyledClosedHours>{'Closed'}</StyledClosedHours>)
                                      
                                      : 
                                      (<StyledHoursField>{ifKansas?'Closed':storeState[`${day}C`]}</StyledHoursField>)
                                      
                                      }
                                </StyledHoursRow>
                            ))}

                            
                        </StyledHoursContainer>    
                        
                        

                        <StyledButtonContainer>
                            <AppointmentButton
                                openModal={openModal}
                                type={t('appointmentsType.bookAppointment')}
                                isBottom={true}
                                
                            />
                        </StyledButtonContainer>
                    </>
                )}
                
            </StyledStoreInformationContainer>
        </>
    );
};

Store.propTypes = {
    openModal: PropTypes.func.isRequired,
};
