import React from 'react';
import styled from 'styled-components';

import Config from 'core/scripts/Config';
import { getHeaderStyle, getLinkStyle } from './style';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';

const StyledHeader = styled.header(getHeaderStyle);
const StyledLink = styled.a(getLinkStyle);

const Header = () => {
    return (
        <StyledHeader>
            <StyledLink
                onClick={() => {
                    window.location.href = Config.REDIRECT_URL;
                }}
                data-testid='suitsupply-logo'
            >
                <Logo />
            </StyledLink>
        </StyledHeader>
    );
};

export default Header;
