import { Api, Config } from 'core/scripts';

export const getUserByID = async ({ id }) => {
    return Api(
        `/v2/employees?employeeIds=${id}`,
        {
            method: 'GET',
        },
        (respones) => {
            return respones.data[0];
        },
        () => {},
        Config.EMPLOYEE_API_URL
    );
};

export const getStoreById = async ({ storeId }) => {
    return Api(
        `/stores/storecode/${storeId}`,
        {
            method: 'GET',
        },
        (respones) => {
            return respones.data;
        },
        () => {}
    );
};

export const getStoreHoursById = async ({ storeId }) => {
    return Api(
        `/stores/hours/${storeId}`,
        {
            method: 'GET',
        },
        (respones) => {
            return respones.data;
        },
        () => {}
    );
};

export const getTestemonialsByOffset = async ({ offset, employeeId }) => {
    return Api(
        `/v2/employee/${employeeId}/testemonials/offset/${offset}`,
        {
            method: 'GET',
        },
        (respones) => {
            return respones.data;
        },
        () => {},
        Config.EMPLOYEE_API_URL
    );
};
