import { mq } from '../../styles/mediaqueries';
import { text } from 'core/styles/variables';

export const getStyledShareButton = () => {
    return {
        position: 'fixed',
        top: '50px',
        right: '10px',
        padding: '12px 13.5px',
        color: text,
        cursor: 'pointer',
        border: '1px solid #E1E1E1',
        borderRadius: '50%',
        backgroundColor: '#fff',
        transition: 'all .3s ease-out',
        zIndex: '10',

        '&:hover': {
            borderColor: '#000',
        },

        svg: {
            width: '13px',
            height: '13px',
        },

        [mq[1]]: {
            top: '70px',
            right: '15px',
            padding: '12px 17px',
        },

        [mq[3]]: {
            right: '30px',
        },
    };
};

export const getStyledShareButtonContainer = () => {
    return {
        maxWidth: '345px',
        width: 'auto',
        height: '70px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #E1E1E1',
        margin: '54px auto 34px',
        padding: '14px 20px 14px 15px',
        cursor: 'pointer',
        transition: 'all .3s ease-out',

        '&:hover': {
            borderColor: '#000',
        },

        svg: {
            minWidth: '13px',
            height: '13px',
        },

        [mq[0]]: {
            maxWidth: '100%',
            width: '410px',
            height: '80px',
            padding: '14px 23px 14px 15px',
        },
    };
};

export const getStyledEmployeeImage = () => {
    return {
        height: '50px',
        width: '50px',
        borderRadius: '50%',
    };
};

export const getStyledShareButtonText = () => {
    return {
        padding: '0 37px 0 10px',
        textAlign: 'left',
        fontSize: '13px',
        lineHeight: '21px',

        [mq[1]]: {
            padding: '0 50px 0 20px',
        },
    };
};
