// Generated by Avo VERSION 139.1.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
    if (typeof window === 'undefined') {
        return;
    }
    var support = {
        searchParams: 'URLSearchParams' in self,
        iterable: 'Symbol' in self && 'iterator' in Symbol,
        blob:
            'FileReader' in self &&
            'Blob' in self &&
            (function () {
                try {
                    new Blob();
                    return true;
                } catch (e) {
                    return false;
                }
            })(),
        formData: 'FormData' in self,
        arrayBuffer: 'ArrayBuffer' in self,
    };

    function isDataView(obj) {
        return obj && DataView.prototype.isPrototypeOf(obj);
    }

    if (support.arrayBuffer) {
        var viewClasses = [
            '[object Int8Array]',
            '[object Uint8Array]',
            '[object Uint8ClampedArray]',
            '[object Int16Array]',
            '[object Uint16Array]',
            '[object Int32Array]',
            '[object Uint32Array]',
            '[object Float32Array]',
            '[object Float64Array]',
        ];

        var isArrayBufferView =
            ArrayBuffer.isView ||
            function (obj) {
                return (
                    obj &&
                    viewClasses.indexOf(Object.prototype.toString.call(obj)) >
                        -1
                );
            };
    }

    function normalizeName(name) {
        if (typeof name !== 'string') {
            name = String(name);
        }
        if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
            throw new TypeError('Invalid character in header field name');
        }
        return name.toLowerCase();
    }

    function normalizeValue(value) {
        if (typeof value !== 'string') {
            value = String(value);
        }
        return value;
    }

    // Build a destructive iterator for the value list
    function iteratorFor(items) {
        var iterator = {
            next: function () {
                var value = items.shift();
                return { done: value === undefined, value: value };
            },
        };

        if (support.iterable) {
            iterator[Symbol.iterator] = function () {
                return iterator;
            };
        }

        return iterator;
    }

    function Headers(headers) {
        this.map = {};

        if (headers instanceof Headers) {
            headers.forEach(function (value, name) {
                this.append(name, value);
            }, this);
        } else if (Array.isArray(headers)) {
            headers.forEach(function (header) {
                this.append(header[0], header[1]);
            }, this);
        } else if (headers) {
            Object.getOwnPropertyNames(headers).forEach(function (name) {
                this.append(name, headers[name]);
            }, this);
        }
    }

    Headers.prototype.append = function (name, value) {
        name = normalizeName(name);
        value = normalizeValue(value);
        var oldValue = this.map[name];
        this.map[name] = oldValue ? oldValue + ', ' + value : value;
    };

    Headers.prototype['delete'] = function (name) {
        delete this.map[normalizeName(name)];
    };

    Headers.prototype.get = function (name) {
        name = normalizeName(name);
        return this.has(name) ? this.map[name] : null;
    };

    Headers.prototype.has = function (name) {
        return this.map.hasOwnProperty(normalizeName(name));
    };

    Headers.prototype.set = function (name, value) {
        this.map[normalizeName(name)] = normalizeValue(value);
    };

    Headers.prototype.forEach = function (callback, thisArg) {
        for (var name in this.map) {
            if (this.map.hasOwnProperty(name)) {
                callback.call(thisArg, this.map[name], name, this);
            }
        }
    };

    Headers.prototype.keys = function () {
        var items = [];
        this.forEach(function (value, name) {
            items.push(name);
        });
        return iteratorFor(items);
    };

    Headers.prototype.values = function () {
        var items = [];
        this.forEach(function (value) {
            items.push(value);
        });
        return iteratorFor(items);
    };

    Headers.prototype.entries = function () {
        var items = [];
        this.forEach(function (value, name) {
            items.push([name, value]);
        });
        return iteratorFor(items);
    };

    if (support.iterable) {
        Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
    }

    function consumed(body) {
        if (body.bodyUsed) {
            return Promise.reject(new TypeError('Already read'));
        }
        body.bodyUsed = true;
    }

    function fileReaderReady(reader) {
        return new Promise(function (resolve, reject) {
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function () {
                reject(reader.error);
            };
        });
    }

    function readBlobAsArrayBuffer(blob) {
        var reader = new FileReader();
        var promise = fileReaderReady(reader);
        reader.readAsArrayBuffer(blob);
        return promise;
    }

    function readBlobAsText(blob) {
        var reader = new FileReader();
        var promise = fileReaderReady(reader);
        reader.readAsText(blob);
        return promise;
    }

    function readArrayBufferAsText(buf) {
        var view = new Uint8Array(buf);
        var chars = new Array(view.length);

        for (var i = 0; i < view.length; i++) {
            chars[i] = String.fromCharCode(view[i]);
        }
        return chars.join('');
    }

    function bufferClone(buf) {
        if (buf.slice) {
            return buf.slice(0);
        } else {
            var view = new Uint8Array(buf.byteLength);
            view.set(new Uint8Array(buf));
            return view.buffer;
        }
    }

    function Body() {
        this.bodyUsed = false;

        this._initBody = function (body) {
            this._bodyInit = body;
            if (!body) {
                this._bodyText = '';
            } else if (typeof body === 'string') {
                this._bodyText = body;
            } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
                this._bodyBlob = body;
            } else if (
                support.formData &&
                FormData.prototype.isPrototypeOf(body)
            ) {
                this._bodyFormData = body;
            } else if (
                support.searchParams &&
                URLSearchParams.prototype.isPrototypeOf(body)
            ) {
                this._bodyText = body.toString();
            } else if (
                support.arrayBuffer &&
                support.blob &&
                isDataView(body)
            ) {
                this._bodyArrayBuffer = bufferClone(body.buffer);
                // IE 10-11 can't handle a DataView body.
                this._bodyInit = new Blob([this._bodyArrayBuffer]);
            } else if (
                support.arrayBuffer &&
                (ArrayBuffer.prototype.isPrototypeOf(body) ||
                    isArrayBufferView(body))
            ) {
                this._bodyArrayBuffer = bufferClone(body);
            } else {
                this._bodyText = body = Object.prototype.toString.call(body);
            }

            if (!this.headers.get('content-type')) {
                if (typeof body === 'string') {
                    this.headers.set(
                        'content-type',
                        'text/plain;charset=UTF-8'
                    );
                } else if (this._bodyBlob && this._bodyBlob.type) {
                    this.headers.set('content-type', this._bodyBlob.type);
                } else if (
                    support.searchParams &&
                    URLSearchParams.prototype.isPrototypeOf(body)
                ) {
                    this.headers.set(
                        'content-type',
                        'application/x-www-form-urlencoded;charset=UTF-8'
                    );
                }
            }
        };

        if (support.blob) {
            this.blob = function () {
                var rejected = consumed(this);
                if (rejected) {
                    return rejected;
                }

                if (this._bodyBlob) {
                    return Promise.resolve(this._bodyBlob);
                } else if (this._bodyArrayBuffer) {
                    return Promise.resolve(new Blob([this._bodyArrayBuffer]));
                } else if (this._bodyFormData) {
                    throw new Error('could not read FormData body as blob');
                } else {
                    return Promise.resolve(new Blob([this._bodyText]));
                }
            };

            this.arrayBuffer = function () {
                if (this._bodyArrayBuffer) {
                    return (
                        consumed(this) || Promise.resolve(this._bodyArrayBuffer)
                    );
                } else {
                    return this.blob().then(readBlobAsArrayBuffer);
                }
            };
        }

        this.text = function () {
            var rejected = consumed(this);
            if (rejected) {
                return rejected;
            }

            if (this._bodyBlob) {
                return readBlobAsText(this._bodyBlob);
            } else if (this._bodyArrayBuffer) {
                return Promise.resolve(
                    readArrayBufferAsText(this._bodyArrayBuffer)
                );
            } else if (this._bodyFormData) {
                throw new Error('could not read FormData body as text');
            } else {
                return Promise.resolve(this._bodyText);
            }
        };

        if (support.formData) {
            this.formData = function () {
                return this.text().then(decode);
            };
        }

        this.json = function () {
            return this.text().then(JSON.parse);
        };

        return this;
    }

    // HTTP methods whose capitalization should be normalized
    var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

    function normalizeMethod(method) {
        var upcased = method.toUpperCase();
        return methods.indexOf(upcased) > -1 ? upcased : method;
    }

    function Request(input, options) {
        options = options || {};
        var body = options.body;

        if (input instanceof Request) {
            if (input.bodyUsed) {
                throw new TypeError('Already read');
            }
            this.url = input.url;
            this.credentials = input.credentials;
            if (!options.headers) {
                this.headers = new Headers(input.headers);
            }
            this.method = input.method;
            this.mode = input.mode;
            this.signal = input.signal;
            if (!body && input._bodyInit != null) {
                body = input._bodyInit;
                input.bodyUsed = true;
            }
        } else {
            this.url = String(input);
        }

        this.credentials =
            options.credentials || this.credentials || 'same-origin';
        if (options.headers || !this.headers) {
            this.headers = new Headers(options.headers);
        }
        this.method = normalizeMethod(options.method || this.method || 'GET');
        this.mode = options.mode || this.mode || null;
        this.signal = options.signal || this.signal;
        this.referrer = null;

        if ((this.method === 'GET' || this.method === 'HEAD') && body) {
            throw new TypeError('Body not allowed for GET or HEAD requests');
        }
        this._initBody(body);
    }

    Request.prototype.clone = function () {
        return new Request(this, { body: this._bodyInit });
    };

    function decode(body) {
        var form = new FormData();
        body.trim()
            .split('&')
            .forEach(function (bytes) {
                if (bytes) {
                    var split = bytes.split('=');
                    var name = split.shift().replace(/\+/g, ' ');
                    var value = split.join('=').replace(/\+/g, ' ');
                    form.append(
                        decodeURIComponent(name),
                        decodeURIComponent(value)
                    );
                }
            });
        return form;
    }

    function parseHeaders(rawHeaders) {
        var headers = new Headers();
        // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
        // https://tools.ietf.org/html/rfc7230#section-3.2
        var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
        preProcessedHeaders.split(/\r?\n/).forEach(function (line) {
            var parts = line.split(':');
            var key = parts.shift().trim();
            if (key) {
                var value = parts.join(':').trim();
                headers.append(key, value);
            }
        });
        return headers;
    }

    Body.call(Request.prototype);

    function Response(bodyInit, options) {
        if (!options) {
            options = {};
        }

        this.type = 'default';
        this.status = options.status === undefined ? 200 : options.status;
        this.ok = this.status >= 200 && this.status < 300;
        this.statusText = 'statusText' in options ? options.statusText : 'OK';
        this.headers = new Headers(options.headers);
        this.url = options.url || '';
        this._initBody(bodyInit);
    }

    Body.call(Response.prototype);

    Response.prototype.clone = function () {
        return new Response(this._bodyInit, {
            status: this.status,
            statusText: this.statusText,
            headers: new Headers(this.headers),
            url: this.url,
        });
    };

    Response.error = function () {
        var response = new Response(null, { status: 0, statusText: '' });
        response.type = 'error';
        return response;
    };

    var redirectStatuses = [301, 302, 303, 307, 308];

    Response.redirect = function (url, status) {
        if (redirectStatuses.indexOf(status) === -1) {
            throw new RangeError('Invalid status code');
        }

        return new Response(null, {
            status: status,
            headers: { location: url },
        });
    };

    self.DOMException = self.DOMException;
    try {
        new self.DOMException();
    } catch (err) {
        self.DOMException = function (message, name) {
            this.message = message;
            this.name = name;
            var error = Error(message);
            this.stack = error.stack;
        };
        self.DOMException.prototype = Object.create(Error.prototype);
        self.DOMException.prototype.constructor = self.DOMException;
    }

    function fetch(input, init) {
        return new Promise(function (resolve, reject) {
            var request = new Request(input, init);

            if (request.signal && request.signal.aborted) {
                return reject(new self.DOMException('Aborted', 'AbortError'));
            }

            var xhr = new XMLHttpRequest();

            function abortXhr() {
                xhr.abort();
            }

            xhr.onload = function () {
                var options = {
                    status: xhr.status,
                    statusText: xhr.statusText,
                    headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
                };
                options.url =
                    'responseURL' in xhr
                        ? xhr.responseURL
                        : options.headers.get('X-Request-URL');
                var body = 'response' in xhr ? xhr.response : xhr.responseText;
                resolve(new Response(body, options));
            };

            xhr.onerror = function () {
                reject(new TypeError('Network request failed'));
            };

            xhr.ontimeout = function () {
                reject(new TypeError('Network request failed'));
            };

            xhr.onabort = function () {
                reject(new self.DOMException('Aborted', 'AbortError'));
            };

            xhr.open(request.method, request.url, true);

            if (request.credentials === 'include') {
                xhr.withCredentials = true;
            } else if (request.credentials === 'omit') {
                xhr.withCredentials = false;
            }

            if ('responseType' in xhr && support.blob) {
                xhr.responseType = 'blob';
            }

            request.headers.forEach(function (value, name) {
                xhr.setRequestHeader(name, value);
            });

            if (request.signal) {
                request.signal.addEventListener('abort', abortXhr);

                xhr.onreadystatechange = function () {
                    // DONE (success or failure)
                    if (xhr.readyState === 4) {
                        request.signal.removeEventListener('abort', abortXhr);
                    }
                };
            }

            xhr.send(
                typeof request._bodyInit === 'undefined'
                    ? null
                    : request._bodyInit
            );
        });
    }

    fetch.polyfill = true;

    if (!self.fetch) {
        self.fetch = fetch;
        self.Headers = Headers;
        self.Request = Request;
        self.Response = Response;
    }
})();

var __AVO_DEV__ = false;
var __AVO_NOOP__ = false;
var __AVO_LOGGER__ = null;
var __AVO_ENV__ = null;
var __REPORT_FAILURE_AS__ = null;
var __STRICT__ = null;

var __WEB_DEBUGGER__ = true;
const avoInspectorApiKey = 'QgPQlX0qbPrl32gg3cX6';
var __INSPECTOR__ = null;

// polyfill Array.isArray
if (!Array.isArray) {
    Array.isArray = function (arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}

// polyfill Object.assign
if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
        value: function assign(target, varArgs) {
            // .length of function is 2
            if (target == null) {
                // TypeError if undefined or null
                throw new TypeError(
                    'Cannot convert undefined or null to object'
                );
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource != null) {
                    // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (
                            Object.prototype.hasOwnProperty.call(
                                nextSource,
                                nextKey
                            )
                        ) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true,
    });
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
    Object.keys = (function () {
        'use strict';
        var hasOwnProperty = Object.prototype.hasOwnProperty,
            hasDontEnumBug = !{ toString: null }.propertyIsEnumerable(
                'toString'
            ),
            dontEnums = [
                'toString',
                'toLocaleString',
                'valueOf',
                'hasOwnProperty',
                'isPrototypeOf',
                'propertyIsEnumerable',
                'constructor',
            ],
            dontEnumsLength = dontEnums.length;

        return function (obj) {
            if (
                typeof obj !== 'function' &&
                (typeof obj !== 'object' || obj === null)
            ) {
                throw new TypeError('Object.keys called on non-object');
            }

            var result = [],
                prop,
                i;

            for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(prop);
                }
            }

            if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                    if (hasOwnProperty.call(obj, dontEnums[i])) {
                        result.push(dontEnums[i]);
                    }
                }
            }
            return result;
        };
    })();
}

// polyfill Array.indexOf
if (!Array.prototype.indexOf)
    Array.prototype.indexOf = (function (Object, max, min) {
        'use strict';
        return function indexOf(member, fromIndex) {
            if (this === null || this === undefined)
                throw TypeError(
                    'Array.prototype.indexOf called on null or undefined'
                );

            var that = Object(this),
                Len = that.length >>> 0,
                i = min(fromIndex | 0, Len);
            if (i < 0) i = max(0, Len + i);
            else if (i >= Len) return -1;

            if (member === void 0) {
                for (; i !== Len; ++i)
                    if (that[i] === void 0 && i in that) return i; // undefined
            } else if (member !== member) {
                for (; i !== Len; ++i) if (that[i] !== that[i]) return i; // NaN
            } else for (; i !== Len; ++i) if (that[i] === member) return i; // all else

            return -1; // if the value was not found, then return -1
        };
    })(Object, Math.max, Math.min);

var array_difference, AvoAssert, InternalAvoLogger;
array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
        if (a2.indexOf(a1[i]) === -1) {
            result.push(a1[i]);
        }
    }
    return result;
};

AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
        if (typeof obj !== 'object') {
            var message =
                propName +
                ' should be of type object but you provided type ' +
                typeof obj +
                ' with value ' +
                JSON.stringify(obj);
            return [
                {
                    tag: 'expectedObjectType',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof obj,
                },
            ];
        } else {
            return [];
        }
    },

    assertString: function assertString(propertyId, propName, str) {
        if (typeof str !== 'string') {
            var message =
                propName +
                ' should be of type string but you provided type ' +
                typeof str +
                ' with value ' +
                JSON.stringify(str);
            return [
                {
                    tag: 'expectedStringType',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof str,
                },
            ];
        } else {
            return [];
        }
    },

    assertInt: function assertInt(propertyId, propName, int) {
        if (typeof int === 'number' && int !== Math.round(int)) {
            var message =
                propName +
                ' should be of type int but you provided type float with value ' +
                JSON.stringify(int);
            return [
                {
                    tag: 'expectedIntType',
                    propertyId: propertyId,
                    actualType: 'float',
                },
            ];
        } else if (typeof int !== 'number') {
            var message =
                propName +
                ' should be of type int but you provided type ' +
                typeof int +
                ' with value ' +
                JSON.stringify(int);
            return [
                {
                    tag: 'expectedIntType',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof int,
                },
            ];
        } else {
            return [];
        }
    },

    assertLong: function assertLong(propertyId, propName, long) {
        if (typeof long === 'number' && long !== Math.round(long)) {
            var message =
                propName +
                ' should be of type long but you provided type float with value ' +
                JSON.stringify(long);
            return [
                {
                    tag: 'expectedLongType',
                    propertyId: propertyId,
                    actualType: 'float',
                },
            ];
        } else if (typeof long !== 'number') {
            var message =
                propName +
                ' should be of type long but you provided type ' +
                typeof long +
                ' with value ' +
                JSON.stringify(long);
            return [
                {
                    tag: 'expectedLongType',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof long,
                },
            ];
        } else {
            return [];
        }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
        if (typeof float !== 'number') {
            var message =
                propName +
                ' should be of type float but you provided type ' +
                typeof float +
                ' with value ' +
                JSON.stringify(float);
            return [
                {
                    tag: 'expectedFloatType',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof float,
                },
            ];
        } else {
            return [];
        }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
        if (typeof bool !== 'boolean') {
            var message =
                propName +
                ' should be of type boolean but you provided type ' +
                typeof bool +
                ' with value ' +
                JSON.stringify(bool);
            return [
                {
                    tag: 'expectedBoolType',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof bool,
                },
            ];
        } else {
            return [];
        }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
        if (value > max) {
            var message =
                propName +
                ' has a maximum value of ' +
                max +
                ' but you provided the value ' +
                JSON.stringify(value);
            return [
                {
                    tag: 'expectedMax',
                    propertyId: propertyId,
                    message: message,
                },
            ];
        } else {
            return [];
        }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
        if (value < min) {
            var message =
                propName +
                ' has a minimum value of ' +
                min +
                ' but you provided the value ' +
                JSON.stringify(value);
            return [
                {
                    tag: 'expectedMin',
                    propertyId: propertyId,
                    message: message,
                },
            ];
        } else {
            return [];
        }
    },

    assertList: function assertList(propertyId, propName, value) {
        if (!Array.isArray(value)) {
            var message =
                propName +
                ' should be of type list but you provided type ' +
                typeof value;
            return [
                {
                    tag: 'expectedList',
                    propertyId: propertyId,
                    message: message,
                    actualType: typeof value,
                },
            ];
        } else {
            return [];
        }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(
        eventName,
        input,
        spec
    ) {
        var additionalKeys = array_difference(input, spec);
        if (additionalKeys.length) {
            var message =
                'Additional properties when sending event ' +
                eventName +
                ': ' +
                JSON.stringify(additionalKeys);
            return [
                {
                    tag: 'expectedNoAdditionalProperties',
                    additionalProperties: additionalKeys,
                    message: message,
                },
            ];
        } else {
            return [];
        }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(
        eventName,
        input,
        spec
    ) {
        var additionalKeys = array_difference(input, spec);
        if (additionalKeys.length) {
            var message =
                'Additional user properties when sending event ' +
                eventName +
                ': ' +
                JSON.stringify(additionalKeys);
            return [
                {
                    tag: 'expectedNoAdditionalUserProperties',
                    additionalProperties: additionalKeys,
                    message: message,
                },
            ];
        } else {
            return [];
        }
    },
};

const WebDebuggerAssertions = {
    hasKey(obj, key) {
        return obj && obj[key] != null;
    },
    assertKey(obj, key, assert) {
        if (this.hasKey(obj, key) && assert(obj[key])) {
            return true;
        } else {
            throw new Error(
                'WebDebugger: Object key: "' + key + '" failed assertion.'
            );
        }
    },
    assertKeys(obj, arrayOfAssertions) {
        return arrayOfAssertions.every((assertionForKey) => {
            if (
                this.hasKey(assertionForKey, 'key') &&
                this.hasKey(assertionForKey, 'assert')
            ) {
                return this.assertKey(
                    obj,
                    assertionForKey.key,
                    assertionForKey.assert
                );
            } else {
                throw new Error(
                    'WebDebugger: Object form for assertion invalid.'
                );
            }
        });
    },
    assertInt(int) {
        return Number.isInteger(int);
    },
};

var WebDebuggerPosition = {
    position: {
        BOTTOM_RIGHT: 'BottomRight',
        BOTTOM_LEFT: 'BottomLeft',
        TOP_RIGHT: 'TopRight',
        TOP_LEFT: 'TopLeft',
    },
    BottomRight(parameters) {
        return (
            WebDebuggerAssertions.assertKeys(parameters, [
                { key: 'bottom', assert: WebDebuggerAssertions.assertInt },
                { key: 'right', assert: WebDebuggerAssertions.assertInt },
            ]) && {
                position: this.position.BOTTOM_RIGHT,
                bottom: parameters.bottom,
                right: parameters.right,
            }
        );
    },
    BottomLeft(parameters) {
        return (
            WebDebuggerAssertions.assertKeys(parameters, [
                { key: 'bottom', assert: WebDebuggerAssertions.assertInt },
                { key: 'left', assert: WebDebuggerAssertions.assertInt },
            ]) && {
                position: this.position.BOTTOM_LEFT,
                bottom: parameters.bottom,
                left: parameters.left,
            }
        );
    },
    TopRight(parameters) {
        return (
            WebDebuggerAssertions.assertKeys(parameters, [
                { key: 'top', assert: WebDebuggerAssertions.assertInt },
                { key: 'right', assert: WebDebuggerAssertions.assertInt },
            ]) && {
                position: this.position.TOP_RIGHT,
                top: parameters.top,
                right: parameters.right,
            }
        );
    },
    TopLeft(parameters) {
        return (
            WebDebuggerAssertions.assertKeys(parameters, [
                { key: 'top', assert: WebDebuggerAssertions.assertInt },
                { key: 'left', assert: WebDebuggerAssertions.assertInt },
            ]) && {
                position: this.position.TOP_LEFT,
                top: parameters.top,
                left: parameters.left,
            }
        );
    },
};

InternalAvoLogger = {
    logEventSent: function logEventSent(
        eventName,
        eventProperties,
        userProperties,
        groupProperties
    ) {
        const message =
            'Event Sent:' +
            eventName +
            'Event Props:' +
            JSON.stringify(eventProperties) +
            'User Props:' +
            JSON.stringify(userProperties) +
            'Group Props:' +
            JSON.stringify(groupProperties);

        if (
            __AVO_LOGGER__ &&
            __AVO_LOGGER__.logDebug &&
            __AVO_LOGGER__.logDebug(__AVO_ENV__, message)
        ) {
            return;
        }
        typeof console !== 'undefined' &&
            console.log(
                '[avo] Event Sent:',
                eventName,
                'Event Props:',
                eventProperties,
                'User Props:',
                userProperties,
                'Group Props:',
                groupProperties
            );
    },

    log: function log(message) {
        if (
            __AVO_LOGGER__ &&
            __AVO_LOGGER__.logDebug &&
            __AVO_LOGGER__.logDebug(__AVO_ENV__, message)
        ) {
            return;
        }
        typeof console !== 'undefined' && console.log('[avo] ' + message);
    },

    warn: function warn(message) {
        if (
            __AVO_LOGGER__ &&
            __AVO_LOGGER__.logWarn &&
            __AVO_LOGGER__.logWarn(__AVO_ENV__, message)
        ) {
            return;
        }
        typeof console !== 'undefined' && console.warn('[avo] ' + message);
    },

    error: function error(message, error) {
        if (
            __AVO_LOGGER__ &&
            __AVO_LOGGER__.logError &&
            __AVO_LOGGER__.logError(__AVO_ENV__, message + error)
        ) {
            return;
        }
        typeof console !== 'undefined' &&
            console.error('[avo] ' + message, error);
    },
};

var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
    if (typeof window === 'undefined') {
        return;
    }
    if (_avo_sampling_rate > 0) {
        if (Math.random() < _avo_sampling_rate) {
            fetch('https://api.avo.app/i', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            })
                .then(function (res) {
                    return res.json();
                })
                .then(function (data) {
                    _avo_sampling_rate = data.sa;
                })
                .catch(function () {});
        }
    }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
    _avo_invoke_payload({
        ac: 'qVphsWZsML75zmhEKjpD',
        br: 'LMXy5Jhst',
        en: env,
        ev: eventId,
        ha: hash,
        sc: 'JmwsgKTQe50MTmUE9FVs',
        se: new Date().toISOString(),
        so: '54V-2sh1g',
        va: messages.length === 0,
        me: messages,
        or: origin,
    });
};

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
    _avo_invoke_payload({
        ac: 'qVphsWZsML75zmhEKjpD',
        br: 'LMXy5Jhst',
        en: env,
        ty: type,
        sc: 'JmwsgKTQe50MTmUE9FVs',
        se: new Date().toISOString(),
        so: '54V-2sh1g',
        va: messages.length === 0,
        me: messages,
        or: origin,
    });
};

var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
    window.addEventListener('message', function (event) {
        if (event.origin !== 'https://www.avo.app') {
            return;
        }
        var iframe = document.getElementById('avo-debugger');
        if (
            iframe &&
            event &&
            event.data &&
            event.data.type_ === 'avo-debugger-update-style'
        ) {
            iframe.style = event.data.style;
        } else if (
            iframe &&
            event &&
            event.data &&
            event.data.type_ === 'avo-debugger-ready'
        ) {
            var message = {
                type_: 'avo-debugger-boot-events',
                schemaId: 'JmwsgKTQe50MTmUE9FVs',
                href: window.location.href,
                events: _avo_debugger_events_during_boot,
            };
            _avo_debugger_events_during_boot = [];
            _avo_debugger_ready = true;
            iframe.contentWindow.postMessage(
                message,
                'https://www.avo.app/_debugger'
            );
        }
    });
}

_avo_debugger_log = function _avo_debugger_log(
    eventId,
    eventName,
    messages,
    eventProperties,
    userProperties,
    groupProperties
) {
    if (typeof window === 'undefined') {
        return;
    }
    var event = {
        eventId: eventId,
        eventName: eventName,
        messages: messages,
        timestamp: Date.now(),
        eventProperties: eventProperties,
        userProperties: userProperties,
        groupProperties: groupProperties,
    };

    if (_avo_debugger_ready) {
        var message = { type_: 'avo-debugger-events', events: [event] };
        document
            .getElementById('avo-debugger')
            .contentWindow.postMessage(
                message,
                'https://www.avo.app/_debugger'
            );
    } else {
        _avo_debugger_events_during_boot.push(event);
    }
};

function _avo_debugger_send_position(position) {
    if (typeof window === 'undefined') {
        return;
    }
    window.addEventListener('message', function (event) {
        if (event.origin !== 'https://www.avo.app') {
            return;
        }
        if (event.data.type_ === 'avo-debugger-ready' && position !== null) {
            var message = {
                type_: 'avo-debugger-position',
                position: position,
            };
            document
                .getElementById('avo-debugger')
                .contentWindow.postMessage(
                    message,
                    'https://www.avo.app/_debugger'
                );
        }
    });
}

var Language = {
    EN: 'en',
    ES: 'es',
    FR: 'fr',
    DE: 'de',
    IT: 'it',
    SV: 'sv',
    PL: 'pl',
    ZH: 'zh',
};

var LoggedIn = {
    TRUE: 'true',
    FALSE: 'false',
};

var Country = {
    US: 'us',
    CA: 'ca',
    NL: 'nl',
    GB: 'gb',
    DE: 'de',
    FR: 'fr',
    BE: 'be',
    IT: 'it',
    SE: 'se',
    CH: 'ch',
    PL: 'pl',
    ES: 'es',
    FI: 'fi',
    DK: 'dk',
    AT: 'at',
    NO: 'no',
    CZ: 'cz',
    HR: 'hr',
    HU: 'hu',
    EE: 'ee',
    LV: 'lv',
    LT: 'lt',
    GR: 'gr',
    IE: 'ie',
    SG: 'sg',
    IN: 'in',
    AU: 'au',
    CN: 'cn',
    BN: 'bn',
    JP: 'jp',
    HK: 'hk',
    MO: 'mo',
    MV: 'mv',
    MY: 'my',
    IR: 'ir',
    LY: 'ly',
    PT: 'pt',
    RO: 'ro',
    CY: 'cy',
    SK: 'sk',
    BG: 'bg',
    SI: 'si',
    AE: 'ae',
    GH: 'gh',
    GI: 'gi',
    GW: 'gw',
    IS: 'is',
    JE: 'je',
    LI: 'li',
    MC: 'mc',
    MT: 'mt',
    QA: 'qa',
    DO: 'do',
    GP: 'gp',
    MQ: 'mq',
    PR: 'pr',
    TT: 'tt',
};

var PageType = {
    HOME: 'home',
    PRODUCT: 'product',
    CATEGORY: 'category',
    JOURNAL: 'journal',
    STORE: 'store',
    LOOKBUILDER: 'lookbuilder',
    LOGIN: 'login',
    WISHLIST: 'wishlist',
    ACCOUNT: 'account',
    CHECKOUT: 'checkout',
    CART: 'cart',
    SUPPORT: 'support',
    CUSTOMMADE: 'custommade',
    GIFTCARD: 'giftcard',
    ERROR: 'error',
};

var Currency = {
    EUR: 'eur',
    USD: 'usd',
    CNY: 'cny',
    AUD: 'aud',
    BGN: 'bgn',
    CAD: 'cad',
    CZK: 'czk',
    DKK: 'dkk',
    HKD: 'hkd',
    HUF: 'huf',
    ISK: 'isk',
    INR: 'inr',
    JPY: 'jpy',
    CHF: 'chf',
    MOP: 'mop',
    MYR: 'myr',
    NOK: 'nok',
    QAR: 'qar',
    RON: 'ron',
    SGD: 'sgd',
    SEK: 'sek',
    AED: 'aed',
    GBP: 'gbp',
};

var EventLocation = {
    SHOPMINUSTHEMINUSLOOK_SLIDER: 'shop-the-look_slider',
    WISHLIST_PANEL: 'wishlist_panel',
    MINICART_PANEL: 'minicart_panel',
    SHOPMINUSTHEMINUSLOOK_PANEL: 'shop-the-look_panel',
    SIZEMINUSPASSPORT_PANEL: 'size-passport_panel',
    PDP: 'pdp',
    PRODUCTS_MWTW_1: 'products_mwtw_1',
    PRODUCTS_MWTW_2: 'products_mwtw_2',
    PRODUCTS_MWTW_3: 'products_mwtw_3',
    PRODUCTS_MWTW_4: 'products_mwtw_4',
    PRODUCTS_MWTW_5: 'products_mwtw_5',
    SHARE_MINUSBUTTON_MINUSTOP: 'share-button-top',
    SHARE_MINUSBUTTON_MINUSBOTTOM: 'share-button-bottom',
};

function assertStoreId(storeId, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            '-pzN7Bwo63',
            label_ ? 'store_id' + ': ' + label_ : 'store_id',
            storeId
        )
    );
    return messages;
}

function assertLanguage(language, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            '8iHihZ0rO',
            label_ ? 'language' + ': ' + label_ : 'language',
            language
        )
    );
    if (
        'en' !== language &&
        'es' !== language &&
        'fr' !== language &&
        'de' !== language &&
        'it' !== language &&
        'sv' !== language &&
        'pl' !== language &&
        'zh' !== language
    ) {
        var message =
            (label_ ? 'language' + ': ' + label_ : 'language') +
            ' should match one of the following values [ en | es | fr | de | it | sv | pl | zh ] but you provided the value ' +
            language;
        messages = messages.concat([
            {
                tag: 'expectedStringMatch',
                propertyId: '8iHihZ0rO',
                message: message,
            },
        ]);
    }
    return messages;
}

function assertIdentifier(identifier, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'ByxtedqSm',
            label_ ? 'identifier' + ': ' + label_ : 'identifier',
            identifier
        )
    );
    return messages;
}

function assertEmployeeName(employeeName, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'DRGDPSPvIp',
            label_ ? 'employee_name' + ': ' + label_ : 'employee_name',
            employeeName
        )
    );
    return messages;
}

function assertVariantId(variantId, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'F-139zDCY',
            label_ ? 'variant_id' + ': ' + label_ : 'variant_id',
            variantId
        )
    );
    return messages;
}

function assertLoggedIn(loggedIn, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'Kvhizl7kAi',
            label_ ? 'logged_in' + ': ' + label_ : 'logged_in',
            loggedIn
        )
    );
    if ('true' !== loggedIn && 'false' !== loggedIn) {
        var message =
            (label_ ? 'logged_in' + ': ' + label_ : 'logged_in') +
            ' should match one of the following values [ true | false ] but you provided the value ' +
            loggedIn;
        messages = messages.concat([
            {
                tag: 'expectedStringMatch',
                propertyId: 'Kvhizl7kAi',
                message: message,
            },
        ]);
    }
    return messages;
}

function assertVariation(variation, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'L3SQfQSDX',
            label_ ? 'variation' + ': ' + label_ : 'variation',
            variation
        )
    );
    return messages;
}

function assertOptionalProductsCart(productsCart, label_) {
    var messages = [];
    if (productsCart !== undefined && productsCart !== null) {
        messages = messages.concat(
            AvoAssert.assertList('NbRKFviNt', 'products_cart', productsCart)
        );
        productsCart.forEach(function (value, index) {
            messages = messages.concat(
                AvoAssert.assertObject(
                    'NbRKFviNt',
                    'item at index ' + index + ' in products_cart',
                    value
                )
            );
            messages = messages.concat(
                assertAmount(
                    value.amount,
                    'item at index ' + index + ' in products_cart'
                )
            );
            messages = messages.concat(
                assertIdentifier(
                    value.identifier,
                    'item at index ' + index + ' in products_cart'
                )
            );
            messages = messages.concat(
                assertVariation(
                    value.variation,
                    'item at index ' + index + ' in products_cart'
                )
            );
            messages = messages.concat(
                assertCurrency(
                    value.currency,
                    'item at index ' + index + ' in products_cart'
                )
            );
            messages = messages.concat(
                assertVariantId(
                    value.variantId,
                    'item at index ' + index + ' in products_cart'
                )
            );
            messages = messages.concat(
                assertQuantity(
                    value.quantity,
                    'item at index ' + index + ' in products_cart'
                )
            );
        });
    }
    return messages;
}

function assertTitle(title, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'PPpWWiUlN',
            label_ ? 'title' + ': ' + label_ : 'title',
            title
        )
    );
    return messages;
}

function assertLocaleVisited(localeVisited, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'QSRXqGwpG',
            label_ ? 'locale_visited' + ': ' + label_ : 'locale_visited',
            localeVisited
        )
    );
    return messages;
}

function assertAmount(amount, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertFloat(
            'Vk6lWRKl5',
            label_ ? 'amount' + ': ' + label_ : 'amount',
            amount
        )
    );
    messages = messages.concat(
        AvoAssert.assertMin(
            'Vk6lWRKl5',
            label_ ? 'amount' + ': ' + label_ : 'amount',
            0.0,
            amount
        )
    );
    return messages;
}

function assertOptionalReferrer(referrer, label_) {
    var messages = [];
    if (referrer !== undefined && referrer !== null) {
        messages = messages.concat(
            AvoAssert.assertString(
                '_ufgi4Bvc',
                label_ ? 'referrer' + ': ' + label_ : 'referrer',
                referrer
            )
        );
    }
    return messages;
}

function assertCustomDestinationPageName_(customDestinationPageName_, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'avo-enriched-type-page-custom-name',
            label_
                ? 'Custom Destination Page Name' + ': ' + label_
                : 'Custom Destination Page Name',
            customDestinationPageName_
        )
    );
    return messages;
}

function assertCountry(country, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'bCDyX8-bZ',
            label_ ? 'country' + ': ' + label_ : 'country',
            country
        )
    );
    if (
        'us' !== country &&
        'ca' !== country &&
        'nl' !== country &&
        'gb' !== country &&
        'de' !== country &&
        'fr' !== country &&
        'be' !== country &&
        'it' !== country &&
        'se' !== country &&
        'ch' !== country &&
        'pl' !== country &&
        'es' !== country &&
        'fi' !== country &&
        'dk' !== country &&
        'at' !== country &&
        'no' !== country &&
        'cz' !== country &&
        'hr' !== country &&
        'hu' !== country &&
        'ee' !== country &&
        'lv' !== country &&
        'lt' !== country &&
        'gr' !== country &&
        'ie' !== country &&
        'sg' !== country &&
        'in' !== country &&
        'au' !== country &&
        'cn' !== country &&
        'bn' !== country &&
        'jp' !== country &&
        'hk' !== country &&
        'mo' !== country &&
        'mv' !== country &&
        'my' !== country &&
        'ir' !== country &&
        'ly' !== country &&
        'pt' !== country &&
        'ro' !== country &&
        'cy' !== country &&
        'sk' !== country &&
        'bg' !== country &&
        'si' !== country &&
        'ae' !== country &&
        'gh' !== country &&
        'gi' !== country &&
        'gw' !== country &&
        'is' !== country &&
        'je' !== country &&
        'li' !== country &&
        'mc' !== country &&
        'mt' !== country &&
        'qa' !== country &&
        'do' !== country &&
        'gp' !== country &&
        'mq' !== country &&
        'pr' !== country &&
        'tt' !== country
    ) {
        var message =
            (label_ ? 'country' + ': ' + label_ : 'country') +
            ' should match one of the following values [ us | ca | nl | gb | de | fr | be | it | se | ch | pl | es | fi | dk | at | no | cz | hr | hu | ee | lv | lt | gr | ie | sg | in | au | cn | bn | jp | hk | mo | mv | my | ir | ly | pt | ro | cy | sk | bg | si | ae | gh | gi | gw | is | je | li | mc | mt | qa | do | gp | mq | pr | tt ] but you provided the value ' +
            country;
        messages = messages.concat([
            {
                tag: 'expectedStringMatch',
                propertyId: 'bCDyX8-bZ',
                message: message,
            },
        ]);
    }
    return messages;
}

function assertQuantity(quantity, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertInt(
            'fg1kaNXr_',
            label_ ? 'quantity' + ': ' + label_ : 'quantity',
            quantity
        )
    );
    messages = messages.concat(
        AvoAssert.assertMin(
            'fg1kaNXr_',
            label_ ? 'quantity' + ': ' + label_ : 'quantity',
            1,
            quantity
        )
    );
    return messages;
}

function assertPageType(pageType, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'gppkY78PuY',
            label_ ? 'page_type' + ': ' + label_ : 'page_type',
            pageType
        )
    );
    if (
        'home' !== pageType &&
        'product' !== pageType &&
        'category' !== pageType &&
        'journal' !== pageType &&
        'store' !== pageType &&
        'lookbuilder' !== pageType &&
        'login' !== pageType &&
        'wishlist' !== pageType &&
        'account' !== pageType &&
        'checkout' !== pageType &&
        'cart' !== pageType &&
        'support' !== pageType &&
        'custommade' !== pageType &&
        'giftcard' !== pageType &&
        'error' !== pageType
    ) {
        var message =
            (label_ ? 'page_type' + ': ' + label_ : 'page_type') +
            ' should match one of the following values [ home | product | category | journal | store | lookbuilder | login | wishlist | account | checkout | cart | support | custommade | giftcard | error ] but you provided the value ' +
            pageType;
        messages = messages.concat([
            {
                tag: 'expectedStringMatch',
                propertyId: 'gppkY78PuY',
                message: message,
            },
        ]);
    }
    return messages;
}

function assertCurrency(currency, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'jhDpoElQU',
            label_ ? 'currency' + ': ' + label_ : 'currency',
            currency
        )
    );
    if (
        'eur' !== currency &&
        'usd' !== currency &&
        'cny' !== currency &&
        'aud' !== currency &&
        'bgn' !== currency &&
        'cad' !== currency &&
        'czk' !== currency &&
        'dkk' !== currency &&
        'hkd' !== currency &&
        'huf' !== currency &&
        'isk' !== currency &&
        'inr' !== currency &&
        'jpy' !== currency &&
        'chf' !== currency &&
        'mop' !== currency &&
        'myr' !== currency &&
        'nok' !== currency &&
        'qar' !== currency &&
        'ron' !== currency &&
        'sgd' !== currency &&
        'sek' !== currency &&
        'aed' !== currency &&
        'gbp' !== currency
    ) {
        var message =
            (label_ ? 'currency' + ': ' + label_ : 'currency') +
            ' should match one of the following values [ eur | usd | cny | aud | bgn | cad | czk | dkk | hkd | huf | isk | inr | jpy | chf | mop | myr | nok | qar | ron | sgd | sek | aed | gbp ] but you provided the value ' +
            currency;
        messages = messages.concat([
            {
                tag: 'expectedStringMatch',
                propertyId: 'jhDpoElQU',
                message: message,
            },
        ]);
    }
    return messages;
}

function assertLocationId(locationId, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'memdKZdQJ1',
            label_ ? 'location_id' + ': ' + label_ : 'location_id',
            locationId
        )
    );
    return messages;
}

function assertPath(path, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'oWTNrOLzR',
            label_ ? 'path' + ': ' + label_ : 'path',
            path
        )
    );
    return messages;
}

function assertUrl(url, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'olJP9CQ0tb',
            label_ ? 'url' + ': ' + label_ : 'url',
            url
        )
    );
    return messages;
}

function assertUserId(userId, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'piJkZJx8sqS',
            label_ ? 'user_id' + ': ' + label_ : 'user_id',
            userId
        )
    );
    return messages;
}

function assertEventLocation(eventLocation, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'te07FO5wjg',
            label_ ? 'event_location' + ': ' + label_ : 'event_location',
            eventLocation
        )
    );
    if (
        'shop-the-look_slider' !== eventLocation &&
        'wishlist_panel' !== eventLocation &&
        'minicart_panel' !== eventLocation &&
        'shop-the-look_panel' !== eventLocation &&
        'size-passport_panel' !== eventLocation &&
        'pdp' !== eventLocation &&
        'products_mwtw_1' !== eventLocation &&
        'products_mwtw_2' !== eventLocation &&
        'products_mwtw_3' !== eventLocation &&
        'products_mwtw_4' !== eventLocation &&
        'products_mwtw_5' !== eventLocation &&
        'share-button-top' !== eventLocation &&
        'share-button-bottom' !== eventLocation
    ) {
        var message =
            (label_ ? 'event_location' + ': ' + label_ : 'event_location') +
            ' should match one of the following values [ shop-the-look_slider | wishlist_panel | minicart_panel | shop-the-look_panel | size-passport_panel | pdp | products_mwtw_1 | products_mwtw_2 | products_mwtw_3 | products_mwtw_4 | products_mwtw_5 | share-button-top | share-button-bottom ] but you provided the value ' +
            eventLocation;
        messages = messages.concat([
            {
                tag: 'expectedStringMatch',
                propertyId: 'te07FO5wjg',
                message: message,
            },
        ]);
    }
    return messages;
}

function assertEmployeeId(employeeId, label_) {
    var messages = [];
    messages = messages.concat(
        AvoAssert.assertString(
            'vdMICFJ-rs',
            label_ ? 'employee_id' + ': ' + label_ : 'employee_id',
            employeeId
        )
    );
    return messages;
}

function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
}

var Segment;
function setup_(
    options,
    systemProperties,
    SegmentDestination,
    destinationOptions
) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
        __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
        if (options.useProductionKey) {
        } else {
        }

        Segment = SegmentDestination;
        Segment?.make(options.useProductionKey);
        if (__AVO_DEV__) {
            // debug console in Avo
            _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
        }
    }
}

function initAvo(
    options,
    systemProperties,
    destinationOptions,
    SegmentDestination
) {
    if (__AVO_ENV__ !== null) {
        return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
        __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
        __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
        InternalAvoLogger.warn(
            '****************************************************'
        );
        InternalAvoLogger.warn(
            'WARNING Avo cannot be initialized in noop mode in production:'
        );
        InternalAvoLogger.warn('- Overwriting configuration with noop=false.');
        InternalAvoLogger.warn(
            '- Please reach out if you want to be able to run Avo in production mode with noop=true'
        );
        InternalAvoLogger.warn(
            '****************************************************'
        );
        __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
        InternalAvoLogger.log(
            '****************************************************'
        );
        InternalAvoLogger.log(
            'Avo is now initialized in noop mode. This means:'
        );
        InternalAvoLogger.log('- No events will be sent');
        InternalAvoLogger.log('- No network requests are made');
        InternalAvoLogger.log(
            '****************************************************'
        );
    }
    if (options.strict !== undefined) {
        __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
        __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ =
        !__AVO_NOOP__ &&
        ((typeof window !== 'undefined' &&
            window.location.search.indexOf('avo_debug=1') > -1) ||
            (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
        __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
        InternalAvoLogger.warn('Avo Inspector not provided in initAvo() call');
    }
    if (__AVO_ENV__ !== 'prod') {
        __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
        if (options.webDebuggerOptions && options.webDebuggerOptions.position) {
            const {
                BOTTOM_RIGHT,
                BOTTOM_LEFT,
                TOP_RIGHT,
                TOP_LEFT,
            } = WebDebuggerPosition.position;

            try {
                WebDebuggerAssertions.assertKey(
                    options.webDebuggerOptions.position,
                    'position',
                    (position) => {
                        return (
                            position === BOTTOM_RIGHT ||
                            position === BOTTOM_LEFT ||
                            position === TOP_RIGHT ||
                            position === TOP_LEFT
                        );
                    }
                );
            } catch (e) {
                throw new Error(
                    'WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.'
                );
            }

            const top = options.webDebuggerOptions.position.top || 0;
            const right = options.webDebuggerOptions.position.right || 0;
            const bottom = options.webDebuggerOptions.position.bottom || 0;
            const left = options.webDebuggerOptions.position.left || 0;

            let positionParams;

            switch (options.webDebuggerOptions.position.position) {
                case BOTTOM_RIGHT:
                    positionParams = {
                        position: BOTTOM_RIGHT,
                        bottom,
                        right,
                    };
                    break;
                case BOTTOM_LEFT:
                    positionParams = {
                        position: BOTTOM_LEFT,
                        bottom,
                        left,
                    };
                    break;
                case TOP_RIGHT:
                    positionParams = {
                        position: TOP_RIGHT,
                        top,
                        right,
                    };
                    break;
                case TOP_LEFT:
                    positionParams = {
                        position: TOP_LEFT,
                        top,
                        left,
                    };
                    break;
                default:
                    throw new Error(
                        'WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.'
                    );
            }

            _avo_debugger_send_position(positionParams);
        }

        (function () {
            if (typeof window === 'undefined') {
                return;
            }
            var init = function () {
                if (document.getElementById('avo-debugger') === null) {
                    var iframe = document.createElement('iframe');
                    document.body.appendChild(iframe);
                    iframe.id = 'avo-debugger';
                    iframe.src = 'https://www.avo.app/_debugger';
                    iframe.style = 'display: none;';
                }
            };

            if (document.body) {
                init();
            } else {
                document.addEventListener('DOMContentLoaded', init);
            }
        })();
    }
    if (!__AVO_NOOP__) {
        if (__AVO_ENV__ === 'prod') {
        }
        if (__AVO_ENV__ === 'dev') {
        }

        Segment = SegmentDestination;
        if (__AVO_ENV__ === 'prod') {
            Segment?.make && Segment?.make(__AVO_ENV__, null);
        } else if (__AVO_ENV__ === 'dev') {
            Segment?.make && Segment?.make(__AVO_ENV__, null);
        } else {
            console[__REPORT_FAILURE_AS__ || 'error'](
                '[avo] No staging key is set for Segment. Head to destination settings in Avo to set a staging key.'
            );
            Segment?.make && Segment?.make(__AVO_ENV__, null);
        }
        if (__AVO_DEV__) {
            // debug console in Avo
            _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
        }
    }
}

/**
   * Page Loaded: When a page or a virtual page is loaded
  for custommade: products_cart and referrer nog needed
   *
   * When to trigger this event:
   * 1. Custommade Suits page 1:
  
  url:
  suitsupply.com/custommade/suit/fabric
  location_id: suitsupply.com/en-nl/custommade/suit/fabric
  title: "Select your fabric"
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/ilo2Fao9X
   * 2. Custommade Suits - jacket:
  
  url:
  suitsupply.com/custommade/suit/jacket
  location_id: suitsupply.com/en-nl/custommade/suit/jacket
  title: "Customize your jacket"
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/EJGPRxCWM
   * 3. Custommade Suits - jacket - Style:
  
  url:
  suitsupply.com/custommade/suit/jacket/style
  location_id: suitsupply.com/en-nl/custommade/suit/jacket/style
  title: customize your jacket style
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/8DnMiHdiv
   * 4. Custommade Suits - trousers - Style:
  
  url:
  suitsupply.com/custommade/suit/trousers/style
  location_id: suitsupply.com/en-nl/custommade/suit/trousers/style
  title: customize your trousers style
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/Y-U_FnScC
   * 5. Custommade Suits - trousers:
  
  url:
  suitsupply.com/custommade/suit/trousers
  location_id: suitsupply.com/en-nl/custommade/suit/trousers
  title: "Customize your trousers"
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/lDHJUY7uw
   * 6. Custommade Suits - Waistcoat:
  
  url:
  suitsupply.com/custommade/suit/waistcoat
  location_id: suitsupply.com/en-nl/custommade/suit/waistcoat
  title: "Customize your waistcoat"
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/hGYSO6MWH
   * 7. Custommade Suits - summary
  
  url:
  suitsupply.com/custommade/suit/summary/
  location_id: suitsupply.com/en-nl/custommade/suit/summary/
  title: "Your summary"
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226/trigger/tB6E44bgl
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.currency - Currency code, ISO standard in lowercase letters
   * @param {string} properties.country - Local country of website, ISO standard, lowercase
   * @param {string} properties.language - language used on page, ISO standard, lowercase
   * @param {string} properties.url - URL without locale
   * @param {string} properties.locationId - Url with locale
   * @param {string} properties.userId - Unique customer ID
   * @param {string} properties.loggedIn - 'true' or 'false'
   * @param {string} properties.pageType - describes the page_type
   * @param {string} properties.customDestinationPageName_ - Name of the page or screen
   * @param {string} properties.localeVisited - Locale visited, for example en-us or es-us
   * @param {string} properties.title - Pagetitle, lowercase in native English
   * @param {string} properties.path - Path portion of the page’s URL. Equivalent to canonical path which defaults to location.pathname from the DOM API.
   * @param {string} [properties.referrer] - Previous page’s full URL. Equivalent to document.referrer from the DOM API.
  
   * @param {Array<object>} [properties.productsCart] - Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event.
   *
   * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/4l0ZskS226}
   */
export function pageLoaded(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
        // assert properties
        var messages = [];

        messages = messages.concat(assertTitle(properties.title));
        messages = messages.concat(assertPath(properties.path));
        messages = messages.concat(
            AvoAssert.assertNoAdditionalProperties(
                'Page Loaded',
                Object.keys(properties),
                ['title', 'path']
            )
        );
        // debug console in Avo
        if (!__AVO_NOOP__) {
            _avo_invoke(
                __AVO_ENV__,
                '4l0ZskS226',
                '69d859151b62b07967f18ac10189fdfb2ff170b09b3bb0eea53b1e3334e29c22',
                messages.map(function (m) {
                    return Object.assign(
                        {},
                        {
                            tag: m.tag,
                            propertyId: m.propertyId,
                            additionalProperties: m.additionalProperties,
                            actualType: m.actualType,
                        }
                    );
                }),
                'event'
            );
        }

        InternalAvoLogger.logEventSent(
            'Page Loaded',
            {
                title: properties.title,
                path: properties.path,
            },
            {},
            {}
        );
        if (__WEB_DEBUGGER__) {
            // Avo web debugger
            _avo_debugger_log(
                '4l0ZskS226',
                'Page Loaded',
                messages,
                [
                    {
                        id: '8iHihZ0rO',
                        name: 'language',
                        value: properties.language,
                    },
                    {
                        id: 'ByxtedqSm',
                        name: 'identifier',
                        value: properties.identifier,
                    },
                    {
                        id: 'F-139zDCY',
                        name: 'variant_id',
                        value: properties.variantId,
                    },
                    {
                        id: 'Kvhizl7kAi',
                        name: 'logged_in',
                        value: properties.loggedIn,
                    },
                    {
                        id: 'L3SQfQSDX',
                        name: 'variation',
                        value: properties.variation,
                    },
                    {
                        id: 'NbRKFviNt',
                        name: 'products_cart',
                        value: properties.productsCart,
                    },
                    { id: 'PPpWWiUlN', name: 'title', value: properties.title },
                    {
                        id: 'QSRXqGwpG',
                        name: 'locale_visited',
                        value: properties.localeVisited,
                    },
                    {
                        id: 'Vk6lWRKl5',
                        name: 'amount',
                        value: properties.amount,
                    },
                    {
                        id: '_ufgi4Bvc',
                        name: 'referrer',
                        value: properties.referrer,
                    },
                    {
                        id: 'bCDyX8-bZ',
                        name: 'country',
                        value: properties.country,
                    },
                    {
                        id: 'fg1kaNXr_',
                        name: 'quantity',
                        value: properties.quantity,
                    },
                    {
                        id: 'gppkY78PuY',
                        name: 'page_type',
                        value: properties.pageType,
                    },
                    {
                        id: 'jhDpoElQU',
                        name: 'currency',
                        value: properties.currency,
                    },
                    {
                        id: 'memdKZdQJ1',
                        name: 'location_id',
                        value: properties.locationId,
                    },
                    { id: 'oWTNrOLzR', name: 'path', value: properties.path },
                    { id: 'olJP9CQ0tb', name: 'url', value: properties.url },
                    {
                        id: 'piJkZJx8sqS',
                        name: 'user_id',
                        value: properties.userId,
                    },
                ],
                [],
                []
            );
        }
        if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
            // throw exception if messages is not empty
            if (messages.length !== 0) {
                throw new Error(
                    "Error sending event 'Page Loaded': " + messages[0].message
                );
            }
        } else {
            messages.forEach(function (m) {
                console[__REPORT_FAILURE_AS__ || 'error']('[avo] ' + m.message);
            });
        }
    }

    if (!__AVO_NOOP__) {
        if (__INSPECTOR__ != null) {
            __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
                'Page Loaded',
                {
                    currency: properties.currency,
                    country: properties.country,
                    language: properties.language,
                    url: properties.url,
                    location_id: properties.locationId,
                    user_id: properties.userId,
                    logged_in: properties.loggedIn,
                    page_type: properties.pageType,
                    locale_visited: properties.localeVisited,
                    title: properties.title,
                    path: properties.path,
                    referrer: properties.referrer,
                    products_cart:
                        properties.productsCart !== undefined &&
                        properties.productsCart !== null
                            ? properties.productsCart.map(function (value) {
                                  return {
                                      amount: value.amount,
                                      identifier: value.identifier,
                                      variation: value.variation,
                                      currency: value.currency,
                                      variant_id: value.variantId,
                                      quantity: value.quantity,
                                  };
                              })
                            : properties.productsCart,
                },
                '4l0ZskS226',
                '69d859151b62b07967f18ac10189fdfb2ff170b09b3bb0eea53b1e3334e29c22'
            );
        }
        // destination Segment
        Segment?.page
            ? Segment?.page(properties.customDestinationPageName_, {
                  currency: properties.currency,
                  country: properties.country,
                  language: properties.language,
                  url: properties.url,
                  location_id: properties.locationId,
                  user_id: properties.userId,
                  logged_in: properties.loggedIn,
                  page_type: properties.pageType,
                  locale_visited: properties.localeVisited,
                  title: properties.title,
                  path: properties.path,
                  referrer: properties.referrer,
                  products_cart:
                      properties.productsCart !== undefined &&
                      properties.productsCart !== null
                          ? properties.productsCart.map(function (value) {
                                return {
                                    amount: value.amount,
                                    identifier: value.identifier,
                                    variation: value.variation,
                                    currency: value.currency,
                                    variant_id: value.variantId,
                                    quantity: value.quantity,
                                };
                            })
                          : properties.productsCart,
              })
            : InternalAvoLogger.error(
                  'Page method is not implemented in the destination',
                  ''
              );
        Segment?.logEvent('Page Loaded', {
            currency: properties.currency,
            country: properties.country,
            language: properties.language,
            url: properties.url,
            location_id: properties.locationId,
            user_id: properties.userId,
            logged_in: properties.loggedIn,
            page_type: properties.pageType,
            locale_visited: properties.localeVisited,
            title: properties.title,
            path: properties.path,
            referrer: properties.referrer,
            products_cart:
                properties.productsCart !== undefined &&
                properties.productsCart !== null
                    ? properties.productsCart.map(function (value) {
                          return {
                              amount: value.amount,
                              identifier: value.identifier,
                              variation: value.variation,
                              currency: value.currency,
                              variant_id: value.variantId,
                              quantity: value.quantity,
                          };
                      })
                    : properties.productsCart,
        });
    } else {
        // do nothing
    }
}

/**
 * booking_tool_opened: When clicking on "Book your appointment" on the employee profile page
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/kry0pvlyM5/trigger/GGgmr-Xqm
 *
 * @param {object} properties - the properties associatied with this event
 * @param {string} properties.storeId - Store Id of the selected store
 * @param {string} properties.employeeId - Style Advisor ID of the selected style advisor in the booking tool
 * @param {string} properties.employeeName - Name of the style advisor
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/kry0pvlyM5}
 */
export function bookingToolOpened(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
        // assert properties
        var messages = [];
        messages = messages.concat(assertStoreId(properties.storeId));
        messages = messages.concat(assertEmployeeId(properties.employeeId));
        messages = messages.concat(assertEmployeeName(properties.employeeName));
        messages = messages.concat(
            AvoAssert.assertNoAdditionalProperties(
                'booking_tool_opened',
                Object.keys(properties),
                ['storeId', 'employeeId', 'employeeName']
            )
        );
        // debug console in Avo
        if (!__AVO_NOOP__) {
            _avo_invoke(
                __AVO_ENV__,
                'kry0pvlyM5',
                '5bd9cc839b680194a25109159a5fd59cb5055b1c56c53efb57bb80cda1b7c519',
                messages.map(function (m) {
                    return Object.assign(
                        {},
                        {
                            tag: m.tag,
                            propertyId: m.propertyId,
                            additionalProperties: m.additionalProperties,
                            actualType: m.actualType,
                        }
                    );
                }),
                'event'
            );
        }

        InternalAvoLogger.logEventSent(
            'booking_tool_opened',
            {
                store_id: properties.storeId,
                employee_id: properties.employeeId,
                employee_name: properties.employeeName,
            },
            {},
            {}
        );
        if (__WEB_DEBUGGER__) {
            // Avo web debugger
            _avo_debugger_log(
                'kry0pvlyM5',
                'booking_tool_opened',
                messages,
                [
                    {
                        id: '-pzN7Bwo63',
                        name: 'store_id',
                        value: properties.storeId,
                    },
                    {
                        id: 'DRGDPSPvIp',
                        name: 'employee_name',
                        value: properties.employeeName,
                    },
                    {
                        id: 'vdMICFJ-rs',
                        name: 'employee_id',
                        value: properties.employeeId,
                    },
                ],
                [],
                []
            );
        }
        if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
            // throw exception if messages is not empty
            if (messages.length !== 0) {
                throw new Error(
                    "Error sending event 'booking_tool_opened': " +
                        messages[0].message
                );
            }
        } else {
            messages.forEach(function (m) {
                console[__REPORT_FAILURE_AS__ || 'error']('[avo] ' + m.message);
            });
        }
    }

    if (!__AVO_NOOP__) {
        if (__INSPECTOR__ != null) {
            __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
                'booking_tool_opened',
                {
                    store_id: properties.storeId,
                    employee_id: properties.employeeId,
                    employee_name: properties.employeeName,
                },
                'kry0pvlyM5',
                '5bd9cc839b680194a25109159a5fd59cb5055b1c56c53efb57bb80cda1b7c519'
            );
        }
        // destination Segment
        Segment?.logEvent('booking_tool_opened', {
            store_id: properties.storeId,
            employee_id: properties.employeeId,
            employee_name: properties.employeeName,
        });
    } else {
        // do nothing
    }
}

/**
   * share_button_clicked: When clicking on share button in the Employee Profile Page,
  2 variants: top and bottom, described in property name event_location
   *
   * When to trigger this event:
   * 1.
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/ePxeA-Jra8/trigger/58kpY_pVU
   * 2.
   * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/ePxeA-Jra8/trigger/q4D0NjtMb
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.storeId - Store Id of the selected store
   * @param {string} properties.eventLocation - Describes where event is located on the website. Never applicable to 'ecommerce' events, and
  
  - For wishlist panel: wishlist_panel
  - For add to bag panel: minicart_panel
  - For OOS panel: oos
  - For shop the look panel: "products_mwtw_{{number}}"
  - For mix and match panel: mixandmatch
  - for Perfect White shirt finder: pwsf_panel
  - For Size Passport: size-passport_panel
  - For PDP size guide button: pdp
   * @param {string} properties.employeeId - Style Advisor ID of the selected style advisor in the booking tool
   * @param {string} properties.employeeName - Name of the style advisor
   *
   * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/LMXy5Jhst/events/ePxeA-Jra8}
   */
export function shareButtonClicked(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
        // assert properties
        var messages = [];
        messages = messages.concat(assertStoreId(properties.storeId));
        messages = messages.concat(
            assertEventLocation(properties.eventLocation)
        );
        messages = messages.concat(assertEmployeeId(properties.employeeId));
        messages = messages.concat(assertEmployeeName(properties.employeeName));
        messages = messages.concat(
            AvoAssert.assertNoAdditionalProperties(
                'share_button_clicked',
                Object.keys(properties),
                ['storeId', 'eventLocation', 'employeeId', 'employeeName']
            )
        );
        // debug console in Avo
        if (!__AVO_NOOP__) {
            _avo_invoke(
                __AVO_ENV__,
                'ePxeA-Jra8',
                '197358b91a2d474cfef805fc24dd60109156fe9c564898153c75b11a85f85238',
                messages.map(function (m) {
                    return Object.assign(
                        {},
                        {
                            tag: m.tag,
                            propertyId: m.propertyId,
                            additionalProperties: m.additionalProperties,
                            actualType: m.actualType,
                        }
                    );
                }),
                'event'
            );
        }

        InternalAvoLogger.logEventSent(
            'share_button_clicked',
            {
                store_id: properties.storeId,
                event_location: properties.eventLocation,
                employee_id: properties.employeeId,
                employee_name: properties.employeeName,
            },
            {},
            {}
        );
        if (__WEB_DEBUGGER__) {
            // Avo web debugger
            _avo_debugger_log(
                'ePxeA-Jra8',
                'share_button_clicked',
                messages,
                [
                    {
                        id: '-pzN7Bwo63',
                        name: 'store_id',
                        value: properties.storeId,
                    },
                    {
                        id: 'DRGDPSPvIp',
                        name: 'employee_name',
                        value: properties.employeeName,
                    },
                    {
                        id: 'te07FO5wjg',
                        name: 'event_location',
                        value: properties.eventLocation,
                    },
                    {
                        id: 'vdMICFJ-rs',
                        name: 'employee_id',
                        value: properties.employeeId,
                    },
                ],
                [],
                []
            );
        }
        if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
            // throw exception if messages is not empty
            if (messages.length !== 0) {
                throw new Error(
                    "Error sending event 'share_button_clicked': " +
                        messages[0].message
                );
            }
        } else {
            messages.forEach(function (m) {
                console[__REPORT_FAILURE_AS__ || 'error']('[avo] ' + m.message);
            });
        }
    }

    if (!__AVO_NOOP__) {
        if (__INSPECTOR__ != null) {
            __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
                'share_button_clicked',
                {
                    store_id: properties.storeId,
                    event_location: properties.eventLocation,
                    employee_id: properties.employeeId,
                    employee_name: properties.employeeName,
                },
                'ePxeA-Jra8',
                '197358b91a2d474cfef805fc24dd60109156fe9c564898153c75b11a85f85238'
            );
        }
        // destination Segment
        Segment?.logEvent('share_button_clicked', {
            store_id: properties.storeId,
            event_location: properties.eventLocation,
            employee_id: properties.employeeId,
            employee_name: properties.employeeName,
        });
    } else {
        // do nothing
    }
}

export default {
    setAvoLogger: setAvoLogger,
    Language: Language,
    LoggedIn: LoggedIn,
    Country: Country,
    PageType: PageType,
    Currency: Currency,
    EventLocation: EventLocation,
    pageLoaded: pageLoaded,
    bookingToolOpened: bookingToolOpened,
    shareButtonClicked: shareButtonClicked,
    initAvo: initAvo,
    WebDebuggerPosition: WebDebuggerPosition,
    avoInspectorApiKey: avoInspectorApiKey,
};

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["pageLoaded","bookingToolOpened","shareButtonClicked"]
