import { GET_USER, SAVE_USER, SAVE_STORE, SET_STATE } from './actions';

const initialState = {
    user: undefined,
    isLoading: true,
    isModalOpen: false,
    setScroll: false,
    modalType: 'In-store',
    localization: 'en-us',
    store: undefined,
    hours: undefined,
    isTopToastDisplayed: false,
    isBotToastDisplayed: false,
    displayTopShareBtn: true,
    testemonials: [],
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER: {
            return state;
        }
        case SAVE_USER: {
            return { ...state, user: action.payload };
        }
        case SAVE_STORE: {
            return { ...state, store: action.payload };
        }
        case SET_STATE: {
            const key =
                Object.keys(action.payload) && Object.keys(action.payload)[0];
            return { ...state, [key]: action.payload[key] };
        }
        default:
            return state;
    }
};
