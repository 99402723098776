export const breakpoints = [390, 768, 1024, 1440, 1920];
export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
export const mqm = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const dimensions = [
    [
        { width: 780, height: 832 },
        { width: 780, height: 832 },
        { width: 1170, height: 1248 },
    ],
    [
        { width: 1152, height: 750 },
        { width: 1552, height: 750 },
        { width: 1536, height: 1000 },
    ],
    [
        { width: 1024, height: 768 },
        { width: 1536, height: 1002 },
        { width: 2048, height: 1336 },
    ],
    [
        { width: 1080, height: 1266 },
        { width: 1080, height: 1266 },
        { width: 1440, height: 1688 },
    ],
    [
        { width: 1440, height: 1536 },
        { width: 1440, height: 1536 },
        { width: 1920, height: 2048 },
    ],
];
