export const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const getConvertedStoreHours = (hours) => {
    let today = new Date();
    let convertedArray = [];

    function addDays(dateObj, numDays) {
        dateObj.setDate(dateObj.getDate() + numDays);
        return dateObj;
    }

    for (let i = 0; i < 7; i++) {
        // Starting from today
        let day = addDays(new Date(), i);

        /**
         * *In the future, it can be translated into various languages based on the language of the local browser.
         * *Just change field inside toLocaleString first paramether
         * */

        let getDayNum = day.getDate();
        let getDayString = day.toLocaleString('en-us', { weekday: 'long' });
        let getMonth = day.toLocaleString('default', { month: 'short' });

        // Looking for specific day from storeHoursApi array and get back object
        let storeDay = hours.find((e) => e.day == day.getDay());

        // Fill in object with other fields, month, currentDay (number) and currentDayString (string)
        storeDay.month = getMonth;
        storeDay.currentDay = getDayNum;
        storeDay.currentDayString = getDayString;

        // Small fix for first day that is today!
        if (day.getDate() == today.getDate()) {
            storeDay.currentDayString = 'Today';
        }

        convertedArray.push(storeDay);
    }

    return convertedArray;
};
