import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getLoaderStyle, getLoaderStageStyle, StyledLoaderLine } from './style';

const StyledLoader = styled.div(getLoaderStyle);
const StyledLoaderStage = styled.div(getLoaderStageStyle);

export const Loader = ({ isBlack }) => {
    return (
        <StyledLoader isBlack={isBlack}>
            <StyledLoaderStage>
                <StyledLoaderLine isBlack={isBlack} />
            </StyledLoaderStage>
        </StyledLoader>
    );
};

Loader.propTypes = {
    isBlack: PropTypes.bool,
};

Loader.defaultProps = {
    isBlack: true,
};
