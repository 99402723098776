import { createGlobalStyle } from 'styled-components';
import { mq } from './mediaqueries';
import { text } from './variables';

//set margins,padding to 0
//set font-size to 62.5% so that 10px=1rem
//implement screenreader class
//set elements default font,line-height

// eslint-disable-next-line import/no-default-export
export default createGlobalStyle`
html,body {
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
  }

body { 
    color: ${text};
    font-family: GT America,-apple-system,Arial,sans-serif;
    font-size: 1.4rem;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align:center;
    
}

p {
    margin:0;
    font-size: 1.5rem;
    line-height: 22px;
    font-weight: 300;
  }

 button{
    display: block;
    font-family: GT America,-apple-system,Arial,sans-serif;
    font-weight:normal;
     font-size:1.4rem;
     line-height:16px; 
     outline: 0;
     border:1px solid #E8E7E5;
     border-radius:4px; 

     ${[mq[1]]}{
        line-height: 20px;
     }
 }

 .triggerAccessible {
     display: none
 } 

h1{
    margin:0;
    font-size:2.4rem;
    line-height:28px;
    font-weight:normal;

    ${[mq[1]]}{
        font-size:3.2rem;
        line-height:40px;
    }
}

h2{
    margin:0;
    font-size:1.8rem;
    line-height:24px;
    font-weight:normal;
}
span{
    margin:0;
    font-size:1.5rem;
    line-height:26px;
}

.IFrame {
    width: 100%;
    height: 100%;
    border: none;
}

.botToast {
    position: absolute;
    padding: 10px;
    border: 1px solid #e1e1e1;
    width: 185px;
    top:-48px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 4px;
    background: #c6c6c6;
    animation-name: toastAppearance;
    animation-duration: 2.5s;
    
}

@keyframes toastAppearance {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.topToast {
    position: fixed;
    top: 90px;
    right: 10px;
    padding: 10px;
    z-index:10;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    align-self: center;
    background: #c6c6c6;
    animation-name: toastAppearance;
    animation-duration: 2.5s;
    ${[mq[1]]}{
        top: 120px;
        right: 15px; 
    }

    ${[mq[3]]}{
        top: 120px;
        right: 30px;
    }
}

.slick-slide img {
    display: inherit;
}

*:focus {
    outline: 0;
    outline: none;
  }
.
`;
