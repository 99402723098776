export const SAVE_USER = 'SAVE_USER';
export const GET_USER = 'GET_USER';
export const SET_LOADING = 'SET_LOADING';
export const SET_TOAST = 'SET_TOAST';
export const SET_STATE = 'SET_STATE';
export const SAVE_STORE = 'SAVE_STORE';

export const saveUser = (user) => ({
    type: SAVE_USER,
    payload: user,
});

export const getUser = (id) => ({
    type: GET_USER,
    payload: id,
});

export const setState = (payload) => ({
    type: SET_STATE,
    payload,
});

export const saveStore = (storeId) => ({
    type: SAVE_STORE,
    payload: storeId,
});
