import { mq } from 'core/styles/mediaqueries';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export const getAppContainerStyle = () => {
    return {
        width: '100vw',
        height: 'calc(var(--vh, 1vh) * 100)',
        overflow: 'auto',
        overflowX: 'hidden',

        [mq[3]]: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    };
};

export const getStyledImageContainer = () => {
    return {
        flexBasis: '100%',
        height: '475px',

        [mq[1]]: {
            height: '668px',
        },
        [mq[2]]: {
            height: '768px',
        },

        [mq[3]]: {
            position: 'sticky',
            top: '0',
            left: '0',
            flexBasis: '50%',
            height: '100vh',
        },
    };
};

export const getStyledImage = () => {
    return {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: '50% 10%',
    };
};

export const getStyledUserStoreContainer = () => {
    return {
        flexBasis: '100%',
        padding: '0 15px',

        [mq[3]]: {
            flexBasis: '50%',
            padding: '0',
        },
    };
};
