const getConfigVariables = () => {
    switch (window.location.hostname) {
        case 'expert.suitsupply.com':
            return {
                API_URL: `https://appointment.suitsupply.com/api`,
                BOOKING_URL: 'https://appointment.suitsupply.com/v2',
                EMPLOYEE_API_URL:
                    'https://employeeapi-prd-api-web.azurewebsites.net/api',
                GROUP_APPOINTMENT_ID: 'T1EC1Exxr1BcIaEq0njq',
                AVO_ENV: 'Prod',
            };
        case 'acc.expert.suitsupply.com':
        case 'expert-acc-web.azurewebsites.net':
            return {
                API_URL: 'https://acc.appointment.suitsupply.com/api',
                BOOKING_URL: 'https://acc.appointment.suitsupply.com/v2',
                EMPLOYEE_API_URL:
                    'https://employeeapi-tst-api-web.azurewebsites.net/api',
                GROUP_APPOINTMENT_ID: '6J0vJzMo9egISXpngExt',
                AVO_ENV: 'Dev',
            };
        case 'tst.expert.suitsupply.com':
        case 'expert-tst-web.azurewebsites.net':
            return {
                API_URL: 'https://tst.appointment.suitsupply.com/api',
                BOOKING_URL:
                    'https://radicalpersonal-tst-web.azurewebsites.net/v2',
                EMPLOYEE_API_URL:
                    'https://employeeapi-tst-api-web.azurewebsites.net/api',
                GROUP_APPOINTMENT_ID: '6J0vJzMo9egISXpngExt',
                AVO_ENV: 'Dev',
            };
        default:
            return {
                API_URL: `https://tst.appointment.suitsupply.com/api`,
                BOOKING_URL: 'http://localhost:3333',
                EMPLOYEE_API_URL:
                    'https://employeeapi-tst-api-web.azurewebsites.net/api',
                GROUP_APPOINTMENT_ID: '6J0vJzMo9egISXpngExt',
                AVO_ENV: 'Dev',
            };
    }
};
let {
    API_URL,
    BOOKING_URL,
    GROUP_APPOINTMENT_ID,
    EMPLOYEE_API_URL,
    AVO_ENV,
} = getConfigVariables();

// redirect links
const REDIRECT_URL = 'https://suitsupply.com';
const REDIRECT_URL_404 = 'https://suitsupply.com/404';

// share employeeName and Id for utm
const UTM_SHARE_EMPLOYEE = `?utm_source=share_style_advisor&utm_medium=style_advisor_button&utm_campaign=style_advisor_`;

export default {
    API_URL,
    BOOKING_URL,
    REDIRECT_URL,
    REDIRECT_URL_404,
    UTM_SHARE_EMPLOYEE,
    GROUP_APPOINTMENT_ID,
    EMPLOYEE_API_URL,
    AVO_ENV,
};
