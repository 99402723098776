import { mq } from 'core/styles/mediaqueries';

export const getUserContainerStyle = () => {
    return {
        padding: '40px 0 30px',

        [mq[1]]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding: '60px 0 30px',
        },

        [mq[3]]: {
            padding: '150px 15px 30px',
        },
    };
};

export const getStyledName = () => {
    return {
        margin: '0 auto',
        [mq[1]]: {
            width: '100%',
        },
    };
};

export const getStyledStoreName = () => {
    return {
        margin: '9px auto 50px',
        fontSize: '1.4rem',
        lineHeight: '20px',
        color: '#878787',

        [mq[1]]: {
            width: '100%',
            margin: '10px 0 60px',
            lineHeight: '16px',
            color: '#898989',
        },
    };
};

export const getStyledCopyToast = {};

export const getStyledAppointmentButtonContainer = () => {
    return {
        display: 'block',

        [mq[0]]: {
            display: 'inline-block',
        },
    };
};
