import React from 'react'
import { mq,mqm } from 'core/styles/mediaqueries';
import { ArrowIcon } from 'assets/icons/arrow';

// Customer ratings
export const getStyledCustomerContainer = () => {
    return {};
};

export const getStyledCustomerNumbersContainer = () => {
    return {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '72px',

        [mq[0]]: {
            paddingBottom: '80px',
        },
    };
};

export const getStyledCustomerRatingNumber = () => {
    return {
        fontSize: '44px',
        lineHeight: '50px',
        fontWeight: '300',
    };
};

export const getStyledCustomerRatingLabel = () => {
    return {
        fontSize: '14px',
        color: '#898989',
        lineHeight: '16px',
        fontWeight: '200',
        width: '55px',
    };
};

export const getStyledRatingDivider = () => {
    return {
        borderLeft: '1px solid #E1E1E1',
        height: '56px',
        marginTop: '50px',
    };
};

export const getStyledRatingContainer = () => {
    return {
        padding: '30px 46px 0 30px',

        // padding: '30px 0px 0 0px',
    };
};

export const getStyledYearsContainer = () => {
    return {
        padding: '30px 30px 0 48px',
    };
};

export const getStyledCustomerComplimentsDivider = () => {
    return {
        borderTop: '1px solid #E1E1E1',
        width: '100%',

        [mq[0]]: {
            width: '520px',
            margin: '0 auto',
        },
    };
};

// Customer compliments
export const getStyledCustomerComplimentsTitle = () => {
    return {
        fontSize: '18px',
        lineHeight: '24px',
        padding: '80px 0 28px 0',
        fontWeight: 'normal',
        margin: 0,
        width: '520px',
        [mqm[1]]: {
            textAlign: 'left',
            padding: '80px 0 32px 0',
        },
        
    };
};

export const getStyledComplimentsContainer = () => {
    return {
        width: '100%',
        height: '200px',
        margin: 'auto',
        overflowY: ' hidden',
        padding: '0 10px',

        [mq[0]]: {
            width: '380px',
        },
    };
};

export const getStyledComplimentsSliderContainer = () => {
    return {};
};

export const getStyledAvatar = () => {
    return {
        width: 80,
        height: 85,
    };
};

export const getStyledComplimentContainer = () => {
    return {
        width: '80px',
    };
};

export const getStyledComplimentNumber = () => {
    return {
        backgroundColor: '#FFF',
        height: '16px',
        paddingTop: '3px',
        width: '40px',
        border: '1px solid #D1D1D1',
        borderRadius: '20px',
        position: 'relative',
        lineHeight: '13px',
        fontSize: '10px',
        top: '-27px',
        right: '-37px',

        [mq[0]]: {
            right: '-42px',
        },
    };
};

export const getStyledComplimentTag = () => {
    return {
        width: '80px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '200',
        marginTop: '-21px',
        marginLeft: '15px',
    };
};


export function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: window.innerWidth < 440 ? 'none' : 'block',
                width: 25,
                height: 25,
                border: `1px solid #D0D0D0`,
                borderRadius: '100%',
                top: '47px',
                right: '-63px',
            }}
            onClick={onClick}
        >
            <div
                style={{
                    position: 'relative',
                    top: '-12px',
                    right: '-8px',
                    height: '8px',
                    width: '8px',
                }}
            >
                <ArrowIcon />
            </div>
        </div>
    );
}

export function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: window.innerWidth < 440 ? 'none' : 'block',
                width: 25,
                height: 25,
                border: `1px solid #D0D0D0`,
                borderRadius: '100%',
                top: '47px',
                left: '-69px',
            }}
            onClick={onClick}
        >
            <div
                style={{
                    position: 'relative',
                    top: '-12px',
                    right: '-7px',
                    transform: 'rotate(180deg)',
                    height: '8px',
                    width: '8px',
                }}
            >
                <ArrowIcon />
            </div>
        </div>
    );
}


// Customer notes
export const getStyledCustomerNotesContainer = () => {
    return {};
};

export const getStyledCustomerNotesTitle = () => {
    return {
        fontSize: '18px',
        lineHeight: '24px',
        borderBottom: '1px solid #E1E1E1',
        width: '345px',
        display: 'inline-block',
        padding: '0 0 28px 0',
        fontWeight: 'normal',
        width: '520px',
        [mqm[1]]: {
            textAlign: 'left',
            padding: '30px 0 20px 0',
        },
    };
};

export const getStyledCustomerInformationContainer = () => {
    return {};
};

export const getStyledCustomerInformation = () => {
    return {
        width: '520px',
        display: 'inline-block',
        paddingTop: '20px',

        [mqm[1]]: {
            width: '350px',
        },
    };
};

export const getStyledCustomerName = () => {
    return {
        float: 'left',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: '400',
        color: '#2D2E2C',
    };
};

export const getStyledCustomerDate = () => {
    return {
        float: 'right',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: '200',
        color: '#898989',
        paddingRight:'10px',
        
    };
    
};

export const getStyledCustomerComment = () => {
    return {
        textAlign: 'left',
        margin: '20px auto 0',
        fontSize: '16px',
        lineHeight: '24px',
        width: '520px',

        [mqm[1]]: {
            width: '390px',
            fontSize: '18px',
        },
        [mqm[0]]: {
            width: '360px',
            fontSize: '18px',
        },
        
    };
};

export const getStyledCustomerRatingTagContainer = () => {
    return {
        width: '345px',
        margin: '30px auto 0',
        paddingBottom: '20px',
        borderBottom: '1px solid #E1E1E1',

        [mq[0]]: {
            width: '520px',
        },
    };
};

export const getStyledRatingTag = ({ singleTag }) => {
    return {
        fontSize: '12px',
        lineHeight: '18px',
        border: '1px solid #E1E1E1',
        borderRadius: '20px',
        whiteSpace: 'nowrap',
        padding: singleTag ? '6px 6px' : '6px 15px',
        marginLeft: singleTag ? '20px' : '3px',
        marginRight: '3px',
        width: singleTag ? 'auto' : 'auto !important',
    };
};

export const getStyledMoreNotesButton = () => {
    return {
        height: '46px',
        width: '122px',
        backgroundColor: '#fff',
        cursor: 'pointer',
        transition: 'all .3s ease-out',
        margin: '20px auto 30px',
        lineHeight: '16px',
        fontSize: '14px',
        fontWeight: '300',

        '&:hover': {
            backgroundColor: 'none',
            borderColor: '#000',
        },

        [mq[0]]: {
            height: '46px',
            width: '169px',
        },
    };
};

export const getStyledPinterestTitle = () => {
    return {
        lineHeight: '24px',
        fontSize: '18px',

    }
}
