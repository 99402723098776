import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useInView } from 'react-intersection-observer';

import { getTestemonialsByOffset } from 'core/redux/endpoints';
import { setState } from 'core/redux/actions.js';

import { LoaderCircular } from 'core/components/LoaderCircular';

import {
    getStyledPinterestTitle,
    getStyledCustomerContainer,
    getStyledCustomerNumbersContainer,
    getStyledCustomerRatingNumber,
    getStyledCustomerRatingLabel,
    getStyledRatingContainer,
    getStyledRatingDivider,
    getStyledYearsContainer,
    getStyledCustomerComplimentsDivider,
    getStyledCustomerNotesContainer,
    getStyledCustomerNotesTitle,
    getStyledCustomerInformation,
    getStyledCustomerName,
    getStyledCustomerDate,
    getStyledCustomerInformationContainer,
    getStyledCustomerComment,
    getStyledCustomerRatingTagContainer,
    getStyledRatingTag,
    getStyledMoreNotesButton,
    getStyledCustomerComplimentsTitle,
    getStyledComplimentsContainer,
    getStyledComplimentsSliderContainer,
    getStyledAvatar,
    getStyledComplimentContainer,
    getStyledComplimentNumber,
    getStyledComplimentTag,
    NextArrow,
    PrevArrow,
} from './style.js';

// Compliments icons
import CraftsmanshipWithFlair from 'assets/icons/CraftsmanshipWithFlair.png';
import ExpertKnowledge from 'assets/icons/ExpertKnowledge.png';
import ExtremelyPatient from 'assets/icons/ExtremelyPatient.png';
import GreatRetailExperience from 'assets/icons/GreatRetailExperience.png';
import GreatTailoringService from 'assets/icons/GreatTailoringService.png';
import HonestAuthenticAdvice from 'assets/icons/HonestAuthenticAdvice.png';

// Customer ratings
const StyledCustomerContainer = styled.div(getStyledCustomerContainer);
const StyledCustomerRatingContainer = styled.div(
    getStyledCustomerNumbersContainer
);
const StyledCustomerRatingNumber = styled.div(getStyledCustomerRatingNumber);
const StyledCustomerRatingLabel = styled.div(getStyledCustomerRatingLabel);
const StyledRatingContainer = styled.div(getStyledRatingContainer);
const StyledRatingDivider = styled.div(getStyledRatingDivider);
const StyledYearsContainer = styled.div(getStyledYearsContainer);

// Customer compliments
const StyledCustomerComplimentsTitle = styled.h3(
    getStyledCustomerComplimentsTitle
);
const StyledCustomerComplimentsDivider = styled.div(
    getStyledCustomerComplimentsDivider
);
const StyledComplimentsContainer = styled.div(getStyledComplimentsContainer);
const StyledComplimentsSliderContainer = styled.div(
    getStyledComplimentsSliderContainer
);
const StyledAvatar = styled.img(getStyledAvatar);
const StyledComplimentContainer = styled.div(getStyledComplimentContainer);
const StyledComplimentNumber = styled.div(getStyledComplimentNumber);
const StyledComplimentTag = styled.div(getStyledComplimentTag);

// Customer notes
const StyledCustomerNotesContainer = styled.div(
    getStyledCustomerNotesContainer
);
const StyledCustomerNotesTitle = styled.h3(getStyledCustomerNotesTitle);
const StyledCustomerInformation = styled.p(getStyledCustomerInformation);
const StyledCustomerInformationContainer = styled.div(
    getStyledCustomerInformationContainer
);
const StyledCustomerName = styled.span(getStyledCustomerName);
const StyledCustomerDate = styled.span(getStyledCustomerDate);
const StyledCustomerComment = styled.p(getStyledCustomerComment);
const StyledCustomerRatingTagContainer = styled.div(
    getStyledCustomerRatingTagContainer
);
const StyledRatingTag = styled.div(getStyledRatingTag);
const StyledMoreNotesButton = styled.button(getStyledMoreNotesButton);
const StyledPinterestTitle = styled.h3(getStyledPinterestTitle);

export const Customer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ref, inView] = useInView({
        threshold: 0,
    });
    const bottomScrollRef = useRef(null);
    const customerNotesTitleRef = useRef(null);

    const reducerState = useSelector((state) => state?.userReducer);
    const userState = useSelector((state) => state?.userReducer?.user);
    const testemonials = useSelector(
        (state) => state?.userReducer?.testemonials
    );
    const {
        customerRating,
        yearsAtSuitSupply,
        compliments,
        sfscEmployeeId,
    } = userState;

    const [displayMoreNotesBtn, setDisplayMoreNotesBtn] = useState(true);
    const [isLoadingTestemonials, setIsLoadingTestemonials] = useState(false);
    const [offset, setOffset] = useState(3);
    const scriptLoadedRef = useRef(false);

    useEffect(() => {
        if (!scriptLoadedRef.current) {
            const script = document.createElement('script');
            script.async = true;
            script.type = 'text/javascript';
            script.dataset.pinBuild = 'doBuild';
            script.src = '//assets.pinterest.com/js/pinit.js';
            document.body.appendChild(script);

            scriptLoadedRef.current = true;
            if (window.doBuild) window.doBuild();
        }
    });

    useEffect(() => {
        if (!inView && offset !== 3 && !isLoadingTestemonials) {
            setOffset((offset) => (offset = 3));
            dispatch(
                setState({
                    testemonials: [...reducerState.testemonials.slice(0, 3)],
                })
            );
            customerNotesTitleRef.current.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }, [inView]);

    const handleMoreTestemonials = async () => {
        setIsLoadingTestemonials(true);
        let additionalTestimonials = await getTestemonialsByOffset({
            offset: offset,
            employeeId: sfscEmployeeId,
        });
        if (additionalTestimonials.length > 0) {
            dispatch(
                setState({
                    testemonials: [
                        ...reducerState.testemonials,
                        ...additionalTestimonials,
                    ],
                })
            );
            setOffset((offset) => (offset += 3));
            bottomScrollRef.current.scrollIntoView({
                block: 'end',
                behavior: 'smooth',
            });
        } else if (additionalTestimonials.length === 0) {
            setDisplayMoreNotesBtn(false);
        }
        setIsLoadingTestemonials(false);
        return;
    };

    return (
        <StyledCustomerContainer>
            {/* Customer Ratings */}
            {customerRating > 0 && (
                <StyledCustomerRatingContainer>
                    <StyledRatingContainer>
                        <StyledCustomerRatingNumber data-testid='employee-customer-rating'>
                            {customerRating?.toString().replace('.', ',')}
                        </StyledCustomerRatingNumber>
                        <StyledCustomerRatingLabel>
                            {t('customerRating')}
                        </StyledCustomerRatingLabel>
                    </StyledRatingContainer>
                    <StyledRatingDivider />
                    <StyledYearsContainer>
                        <StyledCustomerRatingNumber>
                            {yearsAtSuitSupply}
                        </StyledCustomerRatingNumber>
                        <StyledCustomerRatingLabel>
                            {t('yearsAtSuitsupply')}
                        </StyledCustomerRatingLabel>
                    </StyledYearsContainer>
                </StyledCustomerRatingContainer>
            )}
            {compliments?.length > 0 && (
                <StyledCustomerComplimentsDivider>
                    <StyledCustomerComplimentsTitle>
                        {t('customerCompliments')}
                    </StyledCustomerComplimentsTitle>
                </StyledCustomerComplimentsDivider>
            )}

            {/* Customer Compliments */}
            {compliments?.length > 0 && (
                <StyledComplimentsContainer data-testid='employee-compliments-slider'>
                    <StyledComplimentsSliderContainer>
                        <Slider {...complimentsSettings} >
                            {compliments?.length > 0 &&
                                compliments.map((compliment) => {
                                    for (const [key, value] of Object.entries(
                                        compliment
                                    )) {
                                        return (
                                            <StyledComplimentContainer
                                                key={key}
                                            >
                                                <StyledAvatar
                                                    src={getComplimentIcon(key)}
                                                    alt=""
                                                ></StyledAvatar>
                                                <StyledComplimentNumber>
                                                    {value}
                                                </StyledComplimentNumber>
                                                <StyledComplimentTag>
                                                    {formatComplimentTag(key)}
                                                </StyledComplimentTag>
                                            </StyledComplimentContainer>
                                        );
                                    }
                                })}
                        </Slider>
                    </StyledComplimentsSliderContainer>
                </StyledComplimentsContainer>
            )}

            {userState.pinterestUrl && (
                <div>
                    <StyledPinterestTitle>
                        {t('pinterestTitle')}
                    </StyledPinterestTitle>
                    <a
                        href={userState.pinterestUrl}
                        data-pin-do="embedUser"
                        data-pin-board-width="400"
                        data-pin-scale-height="320"
                        data-pin-scale-width="80"
                    ></a>
                </div>
            )}

            {/* Customer Notes */}
            {/* Remove comments section for now */}
            {/* {testemonials?.length > 0 && (
                <StyledCustomerNotesContainer>
                    <StyledCustomerNotesTitle ref={customerNotesTitleRef}>
                        {t('customerNotes')}
                    </StyledCustomerNotesTitle>
                    {testemonials.map((customer, i) => (
                        <div key={i}>
                            <StyledCustomerInformationContainer>
                                <StyledCustomerInformation>
                                    <StyledCustomerName>
                                        {customer.name &&
                                            customer.name.split(' ')[0]}
                                    </StyledCustomerName>
                                    <StyledCustomerDate>
                                        {customer.commentDate}
                                    </StyledCustomerDate>
                                </StyledCustomerInformation>
                            </StyledCustomerInformationContainer>
                            <StyledCustomerComment>
                                {customer.comment}
                            </StyledCustomerComment>
                            <StyledCustomerRatingTagContainer>
                                <Slider {...settings}>
                                    {customer?.ratingTags?.length > 0 &&
                                        customer.ratingTags.map(
                                            (ratingTag, j) => (
                                                <StyledRatingTag
                                                    key={j}
                                                    singleTag={
                                                        customer?.ratingTags
                                                            ?.length === 1
                                                    }
                                                >
                                                    {ratingTag}
                                                </StyledRatingTag>
                                            )
                                        )}
                                </Slider>
                            </StyledCustomerRatingTagContainer>
                        </div>
                    ))}
                    {displayMoreNotesBtn && !isLoadingTestemonials && (
                        <StyledMoreNotesButton
                            onClick={() => handleMoreTestemonials()}
                            ref={ref}
                        >
                            {t('moreNotes')}
                        </StyledMoreNotesButton>
                    )}
                    {isLoadingTestemonials && (
                        <LoaderCircular>Loading...</LoaderCircular>
                    )}
                    <div ref={bottomScrollRef}></div>
                </StyledCustomerNotesContainer>
            )} */}
        </StyledCustomerContainer>
    );
};

const getComplimentIcon = (iconName) => {
    let complimentIcon;
    iconsArray.forEach((icon) => {
        for (const [key, value] of Object.entries(icon)) {
            if (key === iconName) {
                complimentIcon = value;
            }
        }
    });
    return complimentIcon;
};

const formatComplimentTag = (compliment) => {
    compliment = compliment.replace(/([A-Z])/g, ' $1');
    return compliment.charAt(1) + compliment.slice(2).toLowerCase();
};

const iconsArray = [
    { CraftsmanshipWithFlair: CraftsmanshipWithFlair },
    { ExpertKnowledge: ExpertKnowledge },
    { ExtremelyPatient: ExtremelyPatient },
    { GreatRetailExperience: GreatRetailExperience },
    { GreatTailoringService: GreatTailoringService },
    { HonestAuthenticAdvice: HonestAuthenticAdvice },
];

const settings = {
    dots: false,
    speed: 200,
    infinite: false,
    variableWidth: true,
    slidesToShow: window.innerWidth < 990 ? 1.5 : 2,
    slidesToShow: window.innerWidth < 990 ? 1 : 2,
    arrows: false,
};

const complimentsSettings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};
