import { createGlobalStyle } from 'styled-components';

import GTAmericaStandardLightWoff from 'assets/fonts/gt-america-standard-light.woff';
import GTAmericaStandardLightWoff2 from 'assets/fonts/gt-america-standard-light.woff2';
import GTAmericaStandardLighItalictWoff from 'assets/fonts/gt-america-standard-light-italic.woff';
import GTAmericaStandardLighItalictWoff2 from 'assets/fonts/gt-america-standard-light-italic.woff2';
import GTAmericaStandardRegulartWoff from 'assets/fonts/gt-america-standard-regular.woff';
import GTAmericaStandardRegulartWoff2 from 'assets/fonts/gt-america-standard-regular.woff2';
import GTAmericaStandardRegularItalicWoff from 'assets/fonts/gt-america-standard-regular-italic.woff';
import GTAmericaStandardRegularItalicWoff2 from 'assets/fonts/gt-america-standard-regular-italic.woff2';
import GTAmericaStandardMediumWoff from 'assets/fonts/gt-america-standard-medium.woff';
import GTAmericaStandardMediumWoff2 from 'assets/fonts/gt-america-standard-medium.woff2';
import GTAmericaStandardMediumItalicWoff from 'assets/fonts/gt-america-standard-medium-italic.woff';
import GTAmericaStandardMediumItalicWoff2 from 'assets/fonts/gt-america-standard-medium-italic.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: GT America;
        font-weight: 300;
        font-style: normal;
        src: 
          url(${GTAmericaStandardLightWoff2}) format("woff2"),
          url(${GTAmericaStandardLightWoff}) format("woff");
        font-display:swap;
      }
    
      @font-face {
        font-family: GT America;
        font-weight: 300;
        font-style: italic;
        src: 
          url(${GTAmericaStandardLighItalictWoff2}) format("woff2"),
          url(${GTAmericaStandardLighItalictWoff}) format("woff");
        font-display:swap;
      }
    
      @font-face {
        font-family: GT America;
        font-weight: 400;
        font-style: normal;
        src: 
          url(${GTAmericaStandardRegulartWoff2}) format("woff2"),
          url(${GTAmericaStandardRegulartWoff}) format("woff");
        font-display:swap;
      }
    
      @font-face {
        font-family: GT America;
        font-weight: 400;
        font-style: italic;
        src: 
          url(${GTAmericaStandardRegularItalicWoff2}) format("woff2"),
          url(${GTAmericaStandardRegularItalicWoff}) format("woff");
        font-display:swap;
      }
    
      @font-face {
        font-family: GT America;
        font-weight: 700;
        font-style: normal;
        src: 
          url(${GTAmericaStandardMediumWoff2}) format("woff2"),
          url(${GTAmericaStandardMediumWoff}) format("woff");
        font-display:swap;
      }
    
      @font-face {
        font-family: GT America;
        font-weight: 700;
        font-style: italic;
        src: 
          url(${GTAmericaStandardMediumItalicWoff2}) format("woff2"),
          url(${GTAmericaStandardMediumItalicWoff}) format("woff");
        font-display:swap;
      }
`;
