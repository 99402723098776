import React from 'react';
import { getStyledImage } from './style';
import styled from 'styled-components';

const StyledImage = styled.img(getStyledImage);

const EmployeeImage = ({ breakpoints, formatImgSrcSet, formatSrc }) => {
    return (
        <picture >
            <source
                media={`(min-width: ${breakpoints[4]}px)`}
                srcSet={formatImgSrcSet(4)}
                sizes="50vw"
                data-testid='employee-image-picture'
            />
            <source
                media={`(min-width: ${breakpoints[3]}px)`}
                srcSet={formatImgSrcSet(3)}
                sizes="50vw"
                data-testid='employee-image-picture'
            />
            <source
                media={`(min-width: ${breakpoints[2]}px)`}
                srcSet={formatImgSrcSet(2)}
                sizes="100vw"
                data-testid='employee-image-picture'
            />
            <source
                media={`(min-width: ${breakpoints[1]}px)`}
                srcSet={formatImgSrcSet(1)}
                sizes="100vw"
                data-testid='employee-image-picture'
            />
            <source
                media={`(min-width: ${breakpoints[0]}px)`}
                srcSet={formatImgSrcSet(0)}
                sizes="100vw"
                data-testid='employee-image-picture'
            />
            <StyledImage
                alt="employee image new"
                src={formatSrc(formatImgSrcSet(0))}
            />
        </picture>
    );
};

export default EmployeeImage;
