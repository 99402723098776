import { mq } from '../../styles/mediaqueries';

export const getStyledStoreInformationContainer = () => {
    return {
        position: 'relative',
        [mq[3]]: {
            padding: '0 15px',
        },
    };
};
export const getSyledStoreHeader = () => {
    return {
        padding: '20px 0',
    };
};

export const getStyledStoreImageContainer = () => {
    return {
        width: '100%',
        height: '100%',
        [mq[1]]: {
            margin: '0 auto',
            width: '520px',
            height: '270px',
        },
    };
};

export const getStyledStoreImage = () => {
    return {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };
};

export const getStyledStoreAddressContainer = () => {
    return {
        margin: '40px 0',
    };
};

export const getStyledStoreName = () => {
    return {
        paddingBottom: '10px',
    };
};

export const getStyledStoreAddress = () => {
    return {};
};

export const getStyledHoursHeader = () => {
    return {
        paddingBottom: '10px',
    };
};

export const getStyledHoursContainer = () => {
    return {
        width: '290px',
        margin: '0 auto 60px',
        padding: '0',
        listStyleType: 'none',
    };
};

export const getStyledHoursRow = ({ isBold }) => {
    let fontWeight = '300';

    if (isBold) {
        fontWeight = '500';
    }
    return {
        display: 'flex',
        fontWeight,
    };
};

export const getStyledDaysField = () => {
    return {
        flex: '1 1 0px',
        textAlign: 'left',
        textTransform: 'capitalize',
    };
};

export const getStyledDateField = () => {
    return {
        flex: '1 1 0px',
    };
};

export const getStyledHoursField = () => {
    return {
        flex: '1 1 0px',
    };
};

export const getStyledHoursClosed = () => {
    return {
        textAlign:'right'
    };
};

export const getStyledButtonContainer = () => {
    return {
        display: 'flex',
        marginBottom: '55px',

        [mq[0]]: {
           justifyContent: 'center',
        },

        [mq[1]]: {
            
        },
    };
};

export const getStyledCopyToast = {};
