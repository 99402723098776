import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Config } from 'core/scripts';
import { getUser, setState } from 'core/redux/actions';
import { User } from '../User';
import { Customer } from '../Customer';
import { Store } from '../Store';
import { Modal } from 'core/components/Modal';
import { Loader } from '../Loader';
import Header from '../Header';
import getBookingUrl from '../../utils/getBookingUrl';

import {
    breakpoints,
    heights,
    widths,
    dimensions,
} from 'core/styles/mediaqueries';
import EmployeeImage from './../EmployeeImage/index';
import {
    getAppContainerStyle,
    getStyledImageContainer,
    getStyledImage,
    getStyledUserStoreContainer,
} from './style';
import PromotionAnalytics from 'core/scripts/PromotionAnalytics';
import avo from '../../../avo/avo';

const StyledAppContainer = styled.div(getAppContainerStyle);
const StyledImageContainer = styled.div(getStyledImageContainer);
const StyledImage = styled.img(getStyledImage);
const StyledUserStoreContainer = styled.div(getStyledUserStoreContainer);

export const AppContainer = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const { name, employeeID, localization } = useParams();

    const userState = useSelector((state) => state?.userReducer);
    const { user, isLoading, isModalOpen, modalType, store } = userState;
    useEffect(() => {
        dispatch(getUser(employeeID));
        if (localization) {
            i18n.changeLanguage(localization);
            dispatch(setState({ localization: localization }));
        }
    }, [employeeID]);

    useEffect(() => {
        window.addEventListener('message', (e) => {
            if (e.data === 'closeRadicalPersonalModal') {
                closeModal();
            }
            if (e.data === 'crm-bt-close') {
                closeModal();
            }

            if (e.data === 'scrollToOpeningHours') {
                scrollToOpeningHours();
            }
        });
        PromotionAnalytics('promotionImpression');
        avo.pageLoaded({
            title: `Employee profile page - ${name}`,
            path: '/employee-profile-page',
        });
    }, []);

    const scrollToOpeningHours = () => {
        dispatch(setState({ isModalOpen: false }));
        dispatch(setState({ setScroll: true }));
    };

    const closeWithoutButton = () => {
        dispatch(setState({ isModalOpen: false }));
    };

    const closeModal = () => {
        dispatch(setState({ isModalOpen: false }));
    };

    const openModal = (type) => {
        dispatch(setState({ isModalOpen: true }));
        dispatch(setState({ modalType: type }));
    };

    // Format src set for new images. Add 300px for transformation for edge cases
    const formatImgSrcSet = (idx) => {
        const mapped = dimensions.map((media) =>
            media.map((m) =>
                user.imageUrl
                    .replace(
                        'employeeimages/',
                        `https://cdn.suitsupply.com/image/upload/w_2100,h_2240,c_pad,g_center,b_rgb:efefef/w_${m.width},h_${m.height},c_pad,b_rgb:efefef,q_auto,f_auto/employeeimages/`
                    )
                    .concat(` ${m.width}w`)
            )
        );

        return mapped[idx];
    };

    //Format img fallback
    const formatSrc = (input) => {
        return input[1].slice(0, -5);
    };

    if (isLoading) return <Loader />;

    // Redirect to 404 if not enabled for RP
    // if (!user?.enabledForRadicalPersonalC)
    //     return (
    //         <Route
    //             render={() => (window.location.href = Config.REDIRECT_URL_404)}
    //         />
    //     );

    // Redirect if employee doesn't have image
    // if (!user?.imageUrl)
    //     return (
    //         <Route
    //             render={() => (window.location.href = Config.REDIRECT_URL)}
    //         />
    // );

    return (
        <StyledAppContainer>
            <Header />
            <StyledImageContainer className="new-img">
                <EmployeeImage
                    breakpoints={breakpoints}
                    formatImgSrcSet={formatImgSrcSet}
                    formatSrc={formatSrc}
                />
            </StyledImageContainer>
            <StyledUserStoreContainer>
                <User openModal={openModal} />

                <Customer />
                <Store openModal={openModal} />
            </StyledUserStoreContainer>

            <Modal
                isOpen={isModalOpen}
                modalUrl={getBookingUrl(modalType, user, store)}
                onClose={closeWithoutButton}
            />
        </StyledAppContainer>
    );
};
