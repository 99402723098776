import axios from 'axios';
import Config from '../Config';

export default (
    url = null,
    options = {},
    resolve = () => {},
    reject = () => {},
    baseUrl= Config.API_URL
) => {
    const params = Object.assign(options, {
        url: `${baseUrl}${url}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return axios(params)
        .then((response) => {
            return response;
        })
        .then((response) => resolve(response))
        .catch((err) => {
            return reject(err);
        });
};
