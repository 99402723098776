import { lightGrey } from 'core/styles/variables';
import { mq } from 'core/styles/mediaqueries';

export const getHeaderStyle = () => {
    return {
        flexBasis:'100%',
        textAlign: 'center',
        borderBottom: `1px solid ${lightGrey}`,
    };
};

export const getLinkStyle = () => {
    return {
        display: 'inline-block',
        padding: '9px 6px',
        cursor: 'pointer',
        
        [mq[1]]: {
            padding: '17px 10px 16px',
        },
    };
};
