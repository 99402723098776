import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mq } from 'core/styles/mediaqueries';

export const StyledModal = styled(motion.div)`
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius:0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 20;

    ${[mq[2]]} {
        width: 444px;
        height: calc(100vh - 32px);
        margin: 14px 14px 18px;
    }
`;

export const StyledOverlay = styled(motion.div)`
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;s
    z-index: 15;

    ${[mq[2]]} {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

export const getCloseButtonStyle = {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top:0,
    padding: '22px 22px',
    border: 0,
    backgroundColor: 'transparent',  
    cursor: 'pointer',
    transition: 'all .3s ease-out',

    '&:hover, &:focus': {
        opacity: 0.5,
    },
};

export const modalAnimation = {
    init: {
        y: 100,
    },
    open: {
        y: 0,
    },
    close: {
        y: 100,
    },
};

export const modalAnimationDesktop = {
    init: {
        x: 100,
    },
    open: {
        x: 0,
    },
    close: {
        x: 100,
    },
};

export const overlayAnimation = {
    close: {
        opacity: 0,
    },
    open: {
        opacity: 1,
    },
};
