import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import * as clipboard from 'clipboard-polyfill/text';
import { useTranslation } from 'react-i18next';

import { Analytics, Config } from 'core/scripts';
import { setState } from 'core/redux/actions';
import {
    getStyledShareButton,
    getStyledShareButtonContainer,
    getStyledEmployeeImage,
    getStyledShareButtonText,
} from './style';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';
import avo from '../../../avo/avo';

const StyledShareButton = styled.button(getStyledShareButton);
const StyledShareButtonContainer = styled.button(getStyledShareButtonContainer);
const StlyedEmployeeRoundImage = styled.img(getStyledEmployeeImage);
const StyledShareButtonText = styled.div(getStyledShareButtonText);

const getShareUrl = (id, country, city) =>
    `${window.location.origin}${window.location.pathname}${
        Config.UTM_SHARE_EMPLOYEE
    }${country ?? ''}_${city ?? ''}&utm_content=${id}`;

export const ShareButton = ({
    label,
    labelText,
    isDefault,
    sfscEmployeeId,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0,
    });

    const store = useSelector((state) => state?.userReducer?.store);
    const userState = useSelector((state) => state?.userReducer?.user);
    const displayTopShareBtn = useSelector(
        (state) => state.userReducer.displayTopShareBtn
    );

    useEffect(() => {
        dispatch(setState({ displayTopShareBtn: !inView }));
    }, [inView, dispatch]);

    const { country, city } = store || {};
    const { firstName, imageRoundUrl } = userState;

    const onClick = async () => {
        const shareUrl = getShareUrl(sfscEmployeeId, country, city);
        const shareText = t('mobileShareText', {
            employeeName: firstName,
            profileUrl: shareUrl,
        });
        if (navigator.canShare && navigator.canShare({ text: shareText })) {
            navigator
                .share({
                    text: shareText,
                })
                .catch((_ex) => {
                    // ignore the exception
                });
        } else {
            const toastName = displayTopShareBtn
                ? 'isTopToastDisplayed'
                : 'isBotToastDisplayed';
            // Fade in toast
            dispatch(setState({ [toastName]: true }));

            try {
                await clipboard.writeText(shareUrl);
            } catch {
                // ignore the error
            }

            // Fade out toast
            window.setTimeout(() => {
                dispatch(setState({ [toastName]: false }));
            }, 3000);
        }

        // Google Analytics
        Analytics({
            type: `Share_Button`,
            label: `${sfscEmployeeId}-${firstName}`,
        });
        avo.shareButtonClicked({
            storeId: store.storeCodeC,
            employeeId: userState.employeeId,
            employeeName: `${userState.firstName} ${userState.lastName}`,
            eventLocation: isDefault
                ? 'share-button-top'
                : 'share-button-bottom',
        });
    };

    return (
        <>
            {isDefault ? (
                displayTopShareBtn && (
                    <StyledShareButton
                        onClick={onClick}
                        isDefault={isDefault}
                        className="top-share-btn"
                        data-testid="user-share-button"
                    >
                        <ShareIcon />
                    </StyledShareButton>
                )
            ) : (
                <StyledShareButtonContainer
                    onClick={onClick}
                    className="bot-share-btn"
                    inView={inView}
                    ref={ref}
                >
                    <StlyedEmployeeRoundImage
                        alt="rounded-employee"
                        src={imageRoundUrl}
                    />
                    <StyledShareButtonText>
                        <b>{label}</b>
                        <p>{labelText}</p>
                    </StyledShareButtonText>
                    <ShareIcon />
                </StyledShareButtonContainer>
            )}
        </>
    );
};

ShareButton.propTypes = {
    label: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    employeeName: PropTypes.string,
    sfscEmployeeId: PropTypes.number.isRequired,
    isDefault: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string,
};
