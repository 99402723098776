import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ShareButton } from '../ShareButton';
import { AppointmentButton } from '../AppointmentButton';

import {
    getUserContainerStyle,
    getStyledName,
    getStyledStoreName,
    getStyledCopyToast,
    getStyledAppointmentButtonContainer,
} from './style';

const StyledUserContainer = styled.div(getUserContainerStyle);
const StyledEmployeeName = styled.h1(getStyledName);
const StyledStoreName = styled.p(getStyledStoreName);
const StyledCopyToast = styled.p(getStyledCopyToast);
const StyledAppointmentButtonContainer = styled.div(
    getStyledAppointmentButtonContainer
);

export const User = ({ openModal }) => {
    const { t } = useTranslation();

    const userState = useSelector((state) => state?.userReducer?.user);
    const {
        firstName,
        imageUrl,
        sfscEmployeeId,

    } = userState;
    const storeState = useSelector((state) => state?.userReducer?.store);
    const { name } = storeState || {};

    const isTopToastDisplayed = useSelector(
        (state) => state?.userReducer?.isTopToastDisplayed
    );

    useEffect(() => {
        document.title = `${firstName} ${name && name.length > 0 ? name : ''}`;
    }, [firstName, storeState, name]);

    return (
        <>
            <StyledUserContainer>
                <StyledEmployeeName data-testid='employee-name'>{firstName}</StyledEmployeeName >
                <StyledStoreName data-testid='store-name'>{name}</StyledStoreName>
                <StyledAppointmentButtonContainer>
                    <AppointmentButton
                        openModal={openModal}
                        type={t('appointmentsType.bookAppointment')}
                    />
                </StyledAppointmentButtonContainer>

                <ShareButton
                    label={`${t('happyWithText')} ${firstName}`}
                    labelText={`${t('shareText')}`}
                    isDefault={true}
                    imageUrl={imageUrl}
                    employeeName={firstName}
                    sfscEmployeeId={sfscEmployeeId}
                />
                {isTopToastDisplayed && (
                    <StyledCopyToast className="topToast">
                        {t('copiedToClipboard')}
                    </StyledCopyToast>
                )}
            </StyledUserContainer>
        </>
    );
};

User.propTypes = {
    openModal: PropTypes.func.isRequired,
};
