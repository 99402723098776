import { mq } from 'core/styles/mediaqueries';
import { text } from 'core/styles/variables';

export const getStyledButton = ({ darkStyle, disabledStyle, isBottom }) => {
    let color = text;
    let backgroundColor = '#fff';

    if (darkStyle) {
        color = '#fff';
        backgroundColor = text;
    }

    if (disabledStyle) {
        backgroundColor = '#fafafa';
        color = '#c6c6c6';
    }

    return {
        width: '100%',
        margin: '0 0 10px',
        padding: '12px 6px',
        color,
        backgroundColor,
        cursor: 'pointer',
        transition: 'all .3s ease-out',
        margin: '0 0 10px',
        pointerEvents: disabledStyle ? 'none' : 'auto',

        [mq[0]]: {
            padding: '14px 6px',
            width: isBottom ? '410px' : '250px',
        },

        [mq[1]]: {
            width: isBottom ? '410px' : '250px',
            margin: ' 0 0 10px 0',
            padding: '12px 6px',
        },

        '&:hover': {
            backgroundColor: darkStyle ? '#000' : 'none',
            borderColor: darkStyle ? 'unset' : '#000',
        },

    };
};
