import React, { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import IFrame from 'react-iframe';
import PropTypes from 'prop-types';

import {
    StyledModal,
    StyledOverlay,
    getCloseButtonStyle,
    overlayAnimation,
    modalAnimationDesktop,
    modalAnimation,
} from './style';

const StyledCloseButton = styled.button(getCloseButtonStyle);

const modalRoot = document.getElementById('modal');

const responsiveVariants = () => {
    return window.innerWidth >= 992 ? modalAnimationDesktop : modalAnimation;
};

export const Modal = ({ modalUrl, isOpen, onClose }) => {
    const elRef = useRef(null);

    if (!elRef.current) {
        elRef.current = document.createElement('div');
    }

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event && event.data === 'redirectRadicalPersonalModal') {
                window.location.href = 'https://suitsupply.com';
            }
        });
    });

    useEffect(() => {
        if (modalRoot) {
            modalRoot.appendChild(elRef.current);
        }

        return () => modalRoot.removeChild(elRef.current);
    }, []);

    return createPortal(
        <AnimatePresence>
            {isOpen && (
                <>
                    <StyledModal
                        initial="init"
                        animate="open"
                        exit="close"
                        transition={{ ease: 'easeInOut' }}
                        variants={responsiveVariants()}
                    >
                        <StyledCloseButton onClick={onClose} />

                        <IFrame url={modalUrl} className="IFrame" />
                    </StyledModal>
                    <StyledOverlay
                        initial={isOpen ? 'open' : 'close'}
                        animate="open"
                        exit="close"
                        variants={overlayAnimation}
                        onClick={onClose}
                    />
                </>
            )}
        </AnimatePresence>,
        elRef.current
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
