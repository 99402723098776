import { Config } from '../scripts/index';
import { GROUP_BOOKINGTYPE } from './constants';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const language = urlParams.get('language');

const languageParam = language ? `&language=${language}` : '';
const replaceDiacriticsToUnicode = (storeReference) =>
    storeReference.normalize('NFKD').replace(/[^\w\s.-_/]/g, '');


// Onclick function that sets modal type for buttons
function getBookingUrl(type, user, store) {
    const { sfscEmployeeId } = user;

    const { BOOKING_URL, GROUP_APPOINTMENT_ID } = Config;

    const { storeCodeC,name } = store;
    const analyticsParams = `&ss-medium=bt_panel&ss-source=expert_${name}`
    if (type === GROUP_BOOKINGTYPE) {
        return `${BOOKING_URL}/?screen=timeslot&employee-id=${sfscEmployeeId}&service-id=${GROUP_APPOINTMENT_ID}&store-id=${storeCodeC}&service-type=GROUP`;
    } else
        return `${BOOKING_URL}/?screen=service-id&employee-id=${sfscEmployeeId}&store-id=${storeCodeC}${analyticsParams}`;
}

export default getBookingUrl;
