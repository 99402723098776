import { call, takeLatest, put } from 'redux-saga/effects';

import {
    getUserByID,
    getStoreById,
    getStoreHoursById,
    getTestemonialsByOffset,
} from '../endpoints.js';
import { saveUser, saveStore, GET_USER, setState } from '../actions';

export function* userSaga() {
    yield takeLatest(GET_USER, userSagaFlow);
}

const query = new URLSearchParams(window.location.search);
const storeId = query.get('storeId')


function* userSagaFlow(action) {
    const user = yield call(getUserByID, {
        id: action.payload,
    });
    yield put(saveUser(user));

    if (user) {
        

        const store = yield call(getStoreById, {
            storeId: `${storeId? storeId : user.sfscDefaultLocationStoreId}`,
        });
        // Api call only for hours from store
        // const hours = yield call(getStoreHoursById, {
        //     storeId: `${user.sfscDefaultLocationStoreId}`,
        // });
        const testemonials = yield call(getTestemonialsByOffset, {
            offset: `0`,
            employeeId:user.sfscEmployeeId
        });
        yield put(saveStore(store));
        // Data from store hours Api call placed in state
        // yield put(setState({ hours: hours }));
        yield put(setState({ testemonials: testemonials }));
    }
    yield put(setState({ isLoading: false }));
}
