import { brandColor } from 'core/styles/variables';
import styled, { keyframes, css } from 'styled-components';

export const getLoaderStyle = ({ isBlack }) => {
    let backgroundColor = brandColor;

    if (isBlack) {
        backgroundColor = 'white';
    }

    /**
     * NOTE
     * the loader could'nt have the "position: fixed" by default
     * because it's used inside button > pos: absolute
     * but when we display it as overlay it should have the pos: fixed
     * otherwise, it follow the scroll (e.g. EmployeeBooking screen / RP-438)
     */
    return {
        zIndex: 100,
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 4,
        backgroundColor,
    };
};

export const getLoaderStageStyle = () => {
    return {
        overflow: 'hidden',
        maxWidth: '60%',
        backgroundColor: 'transparent',
        width: 80,
        height: 2,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
};

const loaderLineMove = keyframes`
    0% {
        transform: translateX(-150%)
    }

    45% {
        transform: translateX(0)
    }

    55% {
        transform: translateX(0)
    }

    to {
        transform: translateX(150%)
    }
`;

export const animatedLoaderLineStyle = css`
    ${loaderLineMove} 1.4s ease-in-out infinite
`;

export const StyledLoaderLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.isBlack ? brandColor : 'white')};
    animation: ${animatedLoaderLineStyle};
`;
